import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import CardMedia from '@mui/material/CardMedia';
import { TransitionProps } from '@mui/material/transitions';
import { User, useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';

import sudxLogo from '../assets/SUDx-logo.png';
import { SudxNavBarButton } from '../components/common/SudxNavBarButton';
import { Can } from '../Can';
import { useSelector } from 'react-redux';
import { userProfileSelector } from '../features/users/usersSlice';
import { useEffect } from 'react';
import { PersonRounded } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const NavBar: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorElAdmin, setAnchorElAdmin] = React.useState<null | HTMLElement>(null);
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const userProfile: User = useSelector(userProfileSelector);
  const iframeElement: React.Ref<HTMLIFrameElement> = React.useRef<HTMLIFrameElement>(null);
  const [avatarInitials, setAvatarInitials] = React.useState<string>(`${userProfile?.first_name || ''}${userProfile?.last_name ? ` ${userProfile.last_name[0]}.` : ''}`);

  useEffect(() => {
    if (userProfile && userProfile.first_name) {
      setAvatarInitials(`${userProfile.first_name} ${userProfile.last_name ? `${userProfile.last_name[0]}.` : ''}`)
    }
  }, [userProfile]);

  const handleClickOpen = () => {
    handleCloseNavMenu();
    setOpen(true);
  };

  const handleCloseModal:DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
    {
      console.log("Backdrop click, ignore and keep the modal active")
      return;
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenAdminMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAdmin(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseAdminMenu = () => {
    setAnchorElAdmin(null);
  };

  const handleLogin = async () => {
    try {
      handleLogout();
    } catch(err) {
      console.error('Error handling logout for cleaing stale information in the login process, proceeding with login', err);
    }
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
    handleCloseNavMenu();
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    handleCloseUserMenu();
  };

  return (
    <AppBar position="static">
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{ height: 24, display: { xs: 'none', md: 'flex' } }}
            src={sudxLogo}
          />
          
          {isAuthenticated &&
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to="/dashboard"
                >
                  <Typography textAlign="center">Dashboard</Typography>
                </MenuItem>
                <Can I="view_company_profile" a="company_management">
                  <Can not I="view_companies" a="company_management">
                    <MenuItem
                      onClick={handleCloseNavMenu}
                      component={Link}
                      to="/company_profile"
                    >
                      <Typography textAlign="center">Manage Company</Typography>
                    </MenuItem>
                  </Can>
                </Can>
                <Can I="view_companies" a="company_management">
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to="/companies"
                  >
                    <Typography textAlign="center">Manage Companies</Typography>
                  </MenuItem>
                </Can>
                <Can I="view_users" a="user_management">
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to="/users"
                  >
                    <Typography textAlign="center">Manage Users</Typography>
                  </MenuItem>
                </Can>
              </Menu>
            </Box>
          }
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Box
              component="img"
              sx={{ height: 24 }}
              src={sudxLogo}
            />
          </Typography>
          {isAuthenticated
            && 
            <Box sx={{ flexGrow: 0, pl: 5, display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title="">
                <Box>
                  <SudxNavBarButton
                    onButtonClick={handleCloseNavMenu}
                    linkTo="/dashboard"
                    sx={{ border: '0.25px solid #CBE0EC', borderRadius: '4px' }}
                  >
                    &nbsp;
                    Dashboard
                  </SudxNavBarButton>
                  <Can I="view_users" a="user_management">
                    <Button onClick={handleOpenAdminMenu} sx={{ my: 2, color: '#000000', border: '0.25px solid #CBE0EC', borderRadius: '4px' }}>
                      Admin
                    </Button>
                    <Menu
                      sx={{ mt: '45px' }}
                      id="menu-adminbar"
                      anchorEl={anchorElAdmin}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElAdmin)}
                      onClose={handleCloseAdminMenu}
                    >
                      <Can I="view_companies" a="company_management">
                        <MenuItem
                          onClick={handleCloseAdminMenu}
                          component={Link}
                          to="/companies"
                        >
                          <Typography textAlign="center">Manage Companies</Typography>
                        </MenuItem>
                      </Can>
                      <Can I="view_company_profile" a="company_management">
                        <Can not I="view_companies" a="company_management">
                          <MenuItem
                            onClick={handleCloseNavMenu}
                            component={Link}
                            to="/company_profile"
                          >
                            <Typography textAlign="center">Manage Company</Typography>
                          </MenuItem>
                        </Can>
                      </Can>
                      <Can I="view_users" a="user_management">
                        <MenuItem
                          onClick={handleCloseAdminMenu}
                          component={Link}
                          to="/users"
                        >
                          <Typography textAlign="center">Manage Users</Typography>
                        </MenuItem>
                      </Can>
                    </Menu>
                  </Can>
                </Box>
              </Tooltip>
            </Box>
          }
          {isAuthenticated &&  
            <Box sx={{ display: { xs: 'none', md: 'flex', flexGrow: 1 } }}>
            </Box>
          }
          {
            !isAuthenticated && 
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, textAlign: 'right' }}></Box>
          }
          {isAuthenticated
            ? 
            <Box sx={{ flexGrow: 0, display: 'flex' }}>
              <Button
                onClick={handleClickOpen}
                variant="contained"
                sx={{ backgroundColor: '#ffffff !important', color: '#000000' }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Support&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
              <Tooltip title="Open settings">
                <Box>
                  <Button onClick={handleOpenUserMenu}>
                    <Box sx={{ color: '#000000', verticalAlign: 'middle !important', display: 'flex' }}>
                      <PersonRounded sx={{ lineHeight: '24.5px !important' }} />
                      &nbsp;
                      {avatarInitials}
                    </Box>
                  </Button>
                </Box>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={handleCloseUserMenu}
                  component={Link}
                  to="/profile"
                >
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
                <Divider variant="middle" component="li" />
                <MenuItem
                  onClick={handleCloseUserMenu}
                  component={Link}
                  to="https://sudx.com/terms-conditions"
                  target="_blank"
                >
                  <Typography textAlign="center">Terms & Conditions</Typography>
                </MenuItem>
              </Menu>
            </Box>
            :
            <>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  <MenuItem onClick={handleLogin}>
                    <Typography textAlign="center">Login</Typography>
                  </MenuItem>
                </Menu>
              </Box>
              <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, textAlign: 'right' }}>
                <Button
                  onClick={handleLogin}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Login
                </Button>
              </Box>
            </>
          }
        </Toolbar>
      </Container>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        TransitionComponent={Transition}
        maxWidth="lg"
        fullWidth={true}
        disablePortal={true}
        disableEnforceFocus={true}
        disableAutoFocus={true}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ justifyContent: 'flex-end' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container maxWidth={false} sx={{ paddingLeft: ' 0px !important', paddingRight: '0px !important' }}>
          <CardMedia 
            component="iframe"
            ref={iframeElement}
            src='https://sudx.mojohelpdesk.com/widgets/create_request#/ticket-form/75665'
            sx={{width: '100%', minHeight: 'calc(100vh - 70.5px)', border: 'none', px: 0}}
          >Loading...</CardMedia>
        </Container>
      </Dialog>
    </AppBar>
  );
}
import Highcharts, { TooltipFormatterContextObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import heatmap from 'highcharts/modules/heatmap';
import AccessibilityModule from 'highcharts/modules/accessibility';
import { highChartsMouseOverMethod, highChartsMouseOutMethod } from './misc';

AccessibilityModule(Highcharts);
heatmap(Highcharts);
window.Highcharts = Highcharts;
export interface HeatMapChartProps {
  xAxisCategories: Array<string>;
  type: string;
  tooltipFormatter?: Function;
  colorAxisDataClasses: Array<{
    from: number,
    to: number,
    color: string,
    name: string
  }>
  series: Array<{
    name: string,
    xData: Array<string>,
    yData: Array<string>,
    heatData: number[][]|number[][][],
    coordMode: boolean,
    toolTipEnabled: boolean,
    visible: boolean
  }>;
}

export const HeatMapChart: React.FC<HeatMapChartProps> = (props: HeatMapChartProps) => {
  const defaultTooltipFormatter: Function = (context: TooltipFormatterContextObject) => {
    const point = context.point;
    const series = context.series;
    const categoryXName = series.chart.xAxis[0].categories[point.x];
    const categoryYName = series.chart.yAxis[0].categories[point.y as any];
    return `<p style="margin-top:0;margin-bottom:0.5em">${categoryYName}: ${((point.value || 0) as any).toFixed(0)} ng/ml</p>
            <p style="margin:0">Appt Date: ${categoryXName}</p>`;
  }
  
  const adjustCellHeight: Function = (chart: any) => {
    // Calculate the number of points in the x-axis to determine the grid size
    const pointsInXAxis = chart.series[0].options.data.map((point: any) => point ? point[0] : undefined);
    const uniqueX = [...new Set(pointsInXAxis)];
    const numCols = uniqueX.length;

    // Get the plot width and calculate the desired cell height
    const plotWidth = chart.plotWidth;
    let newCellHeight = plotWidth / numCols;
    if (newCellHeight > 50) {
      newCellHeight = 50;
    }

    // Adjust the chart height based on the cell width to maintain square cells
    // This requires knowing or calculating the number of rows, which might depend on your data structure
    const numRows = chart.series[0].options.rows || chart.series[0].data.length / numCols; // Adjust as necessary
    const newChartHeight = newCellHeight * numRows + chart.plotTop + chart.marginBottom;

    // Set the new chart height
    chart.setSize(null, newChartHeight, false);
  }
  
  const options = {
    chart: {
      type: 'heatmap',
      ignoreHiddenSeries: false,
      animation: false,
      backgroundColor: null,
      marginLeft: 134,
      style: {
        fontFamily: 'SF Pro Text',
      },
      scrollablePlotArea: {
        minWidth: 700,
      },
      events: {
        load: function () {
          adjustCellHeight(this);
        },
        redraw:  function () {
          adjustCellHeight(this);
        },
      }
      /*events: {
        load: historyFormatter,
      },*/
    },
    title: {
      text: ''
    },
    credits: false,
    colorAxis: {
      showInLegend: true,
      dataClasses: props.colorAxisDataClasses
    },
    xAxis: {
      categories: props.xAxisCategories,
      title: {
        enabled: false,
      },
      lineColor: '#EAEBEC',
      labels: {
        style: {
          color: '#7B828B',
          fontFamily: 'SF Pro Text',
          fontSize: '12px',
          textTransform: 'uppercase',
        },
      },
    },
    yAxis: {
      title: {
        enabled: false,
      },
      categories: props.series[0].yData,
      offset: 40,
      staggerLines: 0,
      labels: {
        align: 'left',
        x: -64,
        style: {
          color: '#293241',
        },
      },
      endOnTick: false,
      gridLineColor: '#EAEBEC',
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      layout: 'horizontal',
      floating: true,
      itemMarginTop: 3,
      itemMarginBottom: 3,
      y: -40,
      itemStyle: {
        color: '#293241',
        fontFamily: 'SF Pro Text',
        fontSize: '12px',
        fontWeight: '400',
        textTransform: 'uppercase',
      },
      useHTML: true,
      labelFormatter: function(): any {
        return ((context: any) => {
          if(context.name.indexOf('Transparent Placeholder') > -1) { 
            return '<span class="heatmap-transparent-placeholder-legend"></span>';
          }
          return `${context.name}`;
        })(this);
      }
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 699,
        },
        chartOptions: {
          legend: {
            enabled: false,
          },
        },
      }],
    },
    tooltip: {
      animation: false,
      backgroundColor: '#FFF',
      borderColor: '#EAEBEC',
      borderRadius: 0,
      borderWidth: 1,
      color: '#293241',
      shadow: false,
      padding: 10,
      followPointer: false,
      formatter: function() {
        return (props.tooltipFormatter || defaultTooltipFormatter)(this);
      },
      useHTML: true,
      headerFormat: '',
      shared: false,
      crosshairs: true,
      style: {
        fontFamily: 'SF Pro Text',
        fontSize: '12px',
        fontWeight: '400',
      },
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        groupPadding: 0,
        colsize: 0.95,
        rowsize: 0.93,
        animation: false,
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
        },
        point: {
          /*events: {
            mouseOver: mouseOverEvent,
            mouseOut: mouseOutEvent,
          },*/
        },
      },
    },
    series: props.series.map(seriesData => {
      return {
        data: seriesData.xData.flatMap((xLabel: string, xKey: any) => {
          if(seriesData.coordMode) {
            return seriesData.heatData[xKey] as number[][]
          } else {
            return seriesData.yData.map((yLabel, yKey) => {
              return ([
                props.xAxisCategories.indexOf(xLabel),
                yKey,
                seriesData.heatData[yKey][xKey]
              ])
            });
          }
      }),
        name: seriesData.name,
        tooltip: { enabled: seriesData.toolTipEnabled },
        visible: seriesData.visible,
        enableMouseTracking: true,
        point: {
          events: {
            mouseOver: function (event: any) {
              highChartsMouseOverMethod(this, event);
            },
            mouseOut: function (event: any) {
              highChartsMouseOutMethod(this, event);
            }
          }
        }
      }
    })
  };
  return (
    <HighchartsReact sx={{ width: '100%', overflowY: 'visible !important', overflow: 'visible !important' }}
      highcharts={Highcharts}
      options={options}
      containerProps={{ style: { width: '100%', height: '100%', overflowY: 'visible !important', overflow: 'visible !important' } }}
    />
  )
}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUsers, createUser, updateUser, getUser, getUserProfile, partialUpdateUser } from '../../services/user.service';
import UsersState from '../../models/UsersState';
import { LoadingState } from '../../models/enums';
import UsersQueryParams from '../../models/UsersQueryParams';
import User from '../../models/User';
import RootState from '../../models/RootState';
import UserProfile from '../../models/UserProfile';
import { getRoles } from '../../services/role.service';
import Role from '../../models/Role';

const initialState: UsersState = {
  savingUserStatus: LoadingState.Idle,
  fetchingUserStatus: LoadingState.Idle,
  fetchingUserProfile: LoadingState.Idle,
  status: LoadingState.Idle,
  usersCount: 0,
  filteredUsers: [],
  formUser: {} as User,
  userProfile: {} as UserProfile,
  roles: [],
  fetchingRoles: LoadingState.Idle
};

export const fetchRoles = createAsyncThunk('users/fetchRoles', async () => {
  const response = await getRoles();
  return response.data;
});

export const fetchUserProfile = createAsyncThunk('users/fetchUserProfile', async () => {
  console.log('4.Entering fetch user profile')
  const response = await getUserProfile();
  console.log('9.Exiting fetch user profile')
  return response.data;
});

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (queryParams: UsersQueryParams) => {
  const response = await getUsers(queryParams);
  return response.data;
});

export const fetchUser = createAsyncThunk('users/fetchUser', async (userId: string): Promise<User> => {
  const response = await getUser(userId);
  return response.data as User;
});

export const saveUser = createAsyncThunk('users/saveUser', async (payload: { id?: string, user: User }) => {
  const userId: string | undefined = payload.id;
  try {
    const response = await (userId ? updateUser(userId, payload.user) : createUser(payload.user));
    if (response.error) {
      return;
    } else {
      return response;
    }
  } catch (err) {
    console.error('Error saving user', err);
  }
});

export const updateUserStatus = createAsyncThunk('users/updateStatus', async (payload: { id: string, user: User }) => {
  try {
    const response = await partialUpdateUser(payload.id, payload.user);
    if (response.error) {
      return;
    } else {
      return response.data;
    }
  } catch (err) {
    console.error('Error saving user', err);
  }
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setFormNewUser: (state: UsersState) => {
      state.formUser = {} as User;
    },
    setSaveUserStatus: (state: UsersState, payload) => {
      state.savingUserStatus = payload.payload.savingUserStatus;
    },
    setSnackBar: (state: UsersState, payload) => {
      state.snackBarType = payload.payload.snackBarType;
      state.snackBarText = payload.payload.snackBarText;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRoles.pending, (state, action) => {
        state.fetchingRoles = LoadingState.Loading;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles = action.payload as Array<Role>;
        state.fetchingRoles = LoadingState.Succeeded;
      })
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = LoadingState.Loading;
      })
      .addCase(fetchUsers.fulfilled, (state, action): any => {
        const result: any = action.payload;
        state.usersCount = result.count;
        (result.results as any || []).forEach((user: any) => {
          user.role_names = user.roles.map((role: any) => role.name).join(', ')
        });
        state.filteredUsers = result.results;
        state.status = LoadingState.Succeeded;
      })
      .addCase(fetchUser.pending, (state, action) => {
        state.fetchingUserStatus = LoadingState.Loading;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.formUser = action.payload;
        state.fetchingUserStatus = LoadingState.Succeeded;
      })
      .addCase(fetchUserProfile.pending, (state, action) => {
        console.log('3.Fetch user profile in pending')
        state.fetchingUserProfile = LoadingState.Loading;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        console.log('10.Fetch user profile fulfilled')
        state.fetchingUserProfile = LoadingState.Succeeded;
        state.userProfile = (action.payload || {}) as UserProfile;
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        if (action.payload ) {
          const user: User = action.payload as User;
          const userId = user.id;
          const filteredUsers = state.filteredUsers;
          const filteredUser = filteredUsers.filter(filteredUser => filteredUser.id === userId)[0];
          if (filteredUser) {
            filteredUser.is_active = !filteredUser.is_active;
          }
          state.filteredUsers = filteredUsers;
          state.savingUserStatus = LoadingState.Succeeded;
        } else {
          state.savingUserStatus = LoadingState.Failed;
        }
      })
      .addCase(saveUser.pending, (state, action) => {
        state.savingUserStatus = LoadingState.Loading;
      })
      .addCase(saveUser.fulfilled, (state, action) => {
        if (action.payload ) {
          state.savingUserStatus = LoadingState.Succeeded;
        } else {
          state.savingUserStatus = LoadingState.Failed;
        }
      });
  },
});

export const fetchedUsersSelector = (state: RootState): Array<User> => state.users.filteredUsers;
export const formUserSelector = (state: RootState): User => state.users.formUser;
export const userProfileSelector = (state: RootState): UserProfile => state.users.userProfile;
export const usersStatusSelector = (state: RootState): LoadingState => state.users.status;
export const usersCountSelector = (state: RootState): number => state.users.usersCount;
export const savingUserStatusSelector = (state: RootState): LoadingState => state.users.savingUserStatus;
export const fetchingUserProfileSelector = (state: RootState): LoadingState => state.users.fetchingUserProfile;
export const fetchingUserSelector = (state: RootState): LoadingState => state.users.fetchingUserStatus;
export const fetchingRolesSelector = (state: RootState): LoadingState => state.users.fetchingRoles;
export const rolesSelector = (state: RootState): Array<Role> => state.users.roles;
export const snackBarTextSelector = (state: RootState): string | undefined => state.users.snackBarText;
export const snackBarTypeSelector = (state: RootState): string | undefined => state.users.snackBarType;

export const { setFormNewUser, setSaveUserStatus, setSnackBar } = usersSlice.actions;

export default usersSlice.reducer;

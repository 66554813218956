import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import './App.css';
import { PageLoader } from "./components/PageLoader";
import { AuthenticationGuard } from "./app/AuthenticationGuard";
import { Route, Routes } from "react-router-dom";
import { CallbackPage } from "./pages/CallbackPage";
import { HomePage } from "./pages/HomePage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { UserProfilePage } from "./pages/UserProfilePage";
import { UsersListingPage } from './pages/UsersListingPage'
import { CreateUserPage } from "./pages/CreateUserPage";
import { UserDetailPage } from "./pages/UserDetailPage";
import { CompaniesListingPage } from "./pages/CompaniesListingPage";
import { CreateCompanyPage } from "./pages/CreateCompanyPage";
import { CompanyDetailPage } from "./pages/CompanyDetailPage";
import { CompanyUsersPage } from "./pages/CompanyUsersPage";
import { useAppDispatch } from "./app/store";
import { AbilityContext, defineAbilityFor } from "./Can";
import { fetchUserProfile, userProfileSelector } from "./features/users/usersSlice";
import { useSelector } from "react-redux";
import { ChangePasswordPage } from "./pages/ChangePassword";
import { CompanyProfilePage } from "./pages/CompanyProfilePage";
import { DashboardPage } from "./pages/DashboardPage";
import { LoginPage } from "./pages/LoginPage";
import { TermsAndConditionsPage } from "./pages/TermsAndConditionsPage";

export const App: React.FC = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  const dispatch = useAppDispatch();
  const userProfile = useSelector(userProfileSelector);
  const [ability, setAbility] = useState(defineAbilityFor(userProfile));

  useEffect(() => {
    console.log('1.Dispatch fetch user profile')
    dispatch(fetchUserProfile());
    console.log('2.Fetch user profile dispatched')
  }, [isLoading, isAuthenticated]);
  console.log('Auth0 Load', isLoading, isAuthenticated)
  useEffect(() => {
    setAbility(defineAbilityFor(userProfile))
  }, [userProfile]);

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }
  return (
    <AbilityContext.Provider value={ability}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route
          path="/profile"
          element={<AuthenticationGuard component={UserProfilePage} />}
        />
        <Route
          path="/dashboard"
          element={<AuthenticationGuard component={DashboardPage} />}
        />
        <Route
          path="/company_profile"
          element={<AuthenticationGuard component={CompanyProfilePage} />}
        />
        <Route
          path="/change_password"
          element={<AuthenticationGuard component={ChangePasswordPage} />}
        />
        <Route
          path="/login" element={<LoginPage />}
        />
        <Route
          path="/terms_and_conditions" element={<TermsAndConditionsPage />}
        />
        <Route
          path="/users"
          element={<AuthenticationGuard component={UsersListingPage} />}
        />
        <Route
          path="/users/new"
          element={<AuthenticationGuard component={CreateUserPage} />}
        />
        <Route
          path="/users/:userId/edit"
          element={<AuthenticationGuard component={UserDetailPage} />}
        />
        <Route
          path="/companies"
          element={<AuthenticationGuard component={CompaniesListingPage} />}
        />
        <Route
          path="/companies/new"
          element={<AuthenticationGuard component={CreateCompanyPage} />}
        />
        <Route
          path="/companies/:companyId/edit"
          element={<AuthenticationGuard component={CompanyDetailPage} />}
        />
        <Route
          path="/companies/:companyId/users"
          element={<AuthenticationGuard component={CompanyUsersPage} />}
        />
        <Route path="/callback" element={<CallbackPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AbilityContext.Provider>
  );
};

import { useState, Fragment, SyntheticEvent } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { FieldError } from 'react-hook-form';
import PatientAutoComplete from '../../models/PatientAutoComplete';

interface SudxPatientsAutoCompleteProps {
  handleChange: Function;
  disabled?: boolean;
  error?: FieldError;
  value?: PatientAutoComplete;
  patients: Array<PatientAutoComplete>;
}

export const SudxPatientsAutoComplete: React.FC<SudxPatientsAutoCompleteProps> = (props: SudxPatientsAutoCompleteProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event: SyntheticEvent, value: PatientAutoComplete | null) => {
    if (props.handleChange) {
      props.handleChange({ target: { value } });
    }
  }

  return (
    <Autocomplete
      id="patient-autocomplete"
      open={open}
      noOptionsText="Type to search"
      disabled={props.disabled || false}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={handleChange}
      autoSelect={false}
      isOptionEqualToValue={(option, value) => option.name_dob === value.name_dob}
      getOptionLabel={(option) => option.name_dob}
      options={props.patients || []}
      loading={loading}
      value={props.value?.name_dob ? props.value : null}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!props.error}
          helperText={props.error?.message as string}
          variant="filled"
          className="Filter-field-dashboard-patient"
          placeholder="Type to Search"
          sx={{ width: '300px', height: '32px' }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}

import React from "react";
import { NavBar } from "../app/NavBar";
import { useAuth0 } from "@auth0/auth0-react";
import { User } from "@auth0/auth0-spa-js";
import { useSelector } from "react-redux";
import { userProfileSelector } from "../features/users/usersSlice";
import { GlobalSnackBar } from "../features/users/GlobalSnackBar";

interface Props {
  children: JSX.Element;
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const userProfile: User = useSelector(userProfileSelector);

  return (
    <div className="page-layout">
      {(!isAuthenticated || userProfile) && <NavBar />}
      <div className="page-layout__content">{children}</div>
      <GlobalSnackBar />
    </div>
  );
};

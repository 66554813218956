import { SxProps, TableCell, TableSortLabel } from "@mui/material"
import { ReactNode } from "react";
interface SudxTableSortHeaderCellProps {
  cellKey: string;
  sortDirection: 'asc'|'desc';
  sortOrder: string | undefined;
  onSortClick: Function;
  children: ReactNode;
  sx?: SxProps;
}

export const SudxTableSortHeaderCell: React.FC<SudxTableSortHeaderCellProps> = (props: SudxTableSortHeaderCellProps) => {
  const handleSortClick = () => {
    props.onSortClick(props.cellKey);
  }

  return (
    <TableCell
      key={props.cellKey}
      sortDirection={props.sortDirection}
      sx={props.sx}
    >
    <TableSortLabel
      active={props.sortOrder === props.cellKey}
      direction={props.sortDirection}
      onClick={handleSortClick}
    >
      {props.children}
    </TableSortLabel>
    </TableCell>
  );
};

import { ListItem, Box, Typography } from "@mui/material"

interface DashboardPatientInfoListItemProps {
  title: string;
  caption: string;
  iconSrc: string;
  iconSrcWidth?: string;
}

const DEFAULT_ICON_WIDTH = '36px';

export const DashboardPatientInfoListItem: React.FC<DashboardPatientInfoListItemProps> = ({
  title,
  caption,
  iconSrc,
  iconSrcWidth = DEFAULT_ICON_WIDTH
}: DashboardPatientInfoListItemProps) => {
  return (
    <ListItem className="dashboard-patient-info-li">
      <Box className="dashboard-patient-info-li-text" sx={{
        flexGrow: 1
      }}>
        <Typography variant="h3" sx={{
          fontSize: "17.4px",
          paddingBottom: '10px'
        }}>
          {title}
        </Typography>
        <Typography variant="caption" className="dashboard-patient-info-li-text-caption" sx={{
          fontsize: '12px'
        }}>
          {caption}
        </Typography>
      </Box>
      <Box>
        <Box component="img" src={iconSrc} sx={{ width: iconSrcWidth || DEFAULT_ICON_WIDTH }} />
      </Box>
    </ListItem>
  )
}
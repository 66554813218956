import { Box, Container } from "@mui/material";
import { DashboardFilter } from "./DashboardFilter";
import { DashboardSet1 } from "./DashboardSet1";
import { DashboardSet2 } from "./DashboardSet2";
import { DashboardSet3 } from "./DashboardSet3";
import { useSelector } from "react-redux";
import { LoadingState } from "../../models/enums";
import { dashboardStatusSelector, fetchingPatientDataSelector } from "./dashboardSlice";

export const Dashboard: React.FC = () => {
  const dashboardStatus: LoadingState = useSelector(dashboardStatusSelector);
  const fetchingPatientData: LoadingState = useSelector(fetchingPatientDataSelector);

  return (
    <Box className={(dashboardStatus === LoadingState.Loading || fetchingPatientData === LoadingState.Loading) ? 'shiny-busy' : ''} sx={{ width: '100wh', height: '100vh' }}>
      <Container className="dashboard-page-wrapper" maxWidth={false} sx={{ paddingLeft: '0 !important', paddingRight: '0 !important', fontFamily: '"SF Pro Text", Inter, Roboto, "Helvetica Neue", "Arial Nova", "Nimbus Sans", Arial, sans-serif !important' }}>
        <DashboardFilter />
        <DashboardSet1 />
        <DashboardSet2 />
        <DashboardSet3 />
      </Container>
    </Box>
  )
}
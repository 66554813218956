import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse } from "../models/ApiResponse";
import { AppError } from "../models/AppError";
import { auth0Client } from "./auth0.service";

export const sudxBackendApiServerUrl = `${process.env.REACT_APP_API_SERVER_URL}/api`;
export const sudxPatientApiServerUrl = `${process.env.REACT_APP_API_PATIENTS_URL}`;

export const callSudxBackendApi = async (options: {
  config: AxiosRequestConfig;
}, apiOptions?: { skipAuthToken: boolean }): Promise<ApiResponse> => {
  console.log("Reading the Token from Auth0 Silently, skipToken condition: ", (!apiOptions?.skipAuthToken));
  let token: string = '';
  try {
    if(!apiOptions?.skipAuthToken) {
      console.log("Calling auth0Client.getTokenSilently() with wait");
      token = await auth0Client.getTokenSilently();
    } 
  } catch (error) {
    console.error("Get Token Silently threw exception. ", error);
  }
  console.log("Got Token from Auth0 getTokenSilently(). ");

  try {
    options.config.headers = {
      'content-type': 'application/json',
    };
    if (!apiOptions?.skipAuthToken) {
      options.config.headers.Authorization = `Bearer ${token}`;
    }
    const response: AxiosResponse = await axios(options.config);
    const { data } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    console.error("Error while getting the profile", error);
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && (response.data as AppError).message) {
        message = (response.data as AppError).message;
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: (error as Error).message,
      },
    };
  }
};

import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, Typography } from '@mui/material';
import { North as NorthIcon, South as SouthIcon } from '@mui/icons-material';
import AccessibilityModule from 'highcharts/modules/accessibility';

AccessibilityModule(Highcharts);

interface AverageCurrentScoreChartProps {
  averageValue: number;
  currentValue: number;
  changeValue: number;
}

export const AverageCurrentScoreChart: React.FC<AverageCurrentScoreChartProps> = (props: AverageCurrentScoreChartProps) => {
  const options = {
    chart: {
      className: 'average-current-score-chart',
      type: 'column',
      width: 120,
      height: 300,
      animation: false,
      style: {
        fontFamily: 'SF Pro Text'
      },
      backgroundColor: null,
      margins: 0,
      spacing: [0, 0, 0, 0]
    },
    credits: false,
    title: {
      text: ''
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false,
      min: 0,
      max: 100
    },
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      column: {
        colorByPoint: true
      },
      series: {
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            enabled: false
          }
        },
        animation: false,
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        shadow: false
      }
    },
    series: [{
      data: [
        {
          name: 'Average',
          y: props.averageValue
        },
        {
          name: 'Current',
          y: props.currentValue
        }
      ]
    }],
    colors: ['rgb(61, 90, 128)', 'rgb(152, 193, 217)'] 
  };

  const scoreChange = Math.abs(props.changeValue)
  const changeDirection: JSX.Element = props.changeValue > 0 ? <NorthIcon /> : <SouthIcon />;

  return (
    <Box sx={{
      width: '367.469px',
      marginLeft: '16.272px',
      marginRight: '16.272px',
      paddingLeft: 'auto',
      paddingRight: 'auto',
      backgroundImage: 'linear-gradient(rgb(245, 249, 251), rgb(245, 249, 251))',
      backgroundPositionX: '50%',
      backgroundPositionY: 'calc(100% - 15px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative'
    }}>
      <Box sx={{
        position: 'absolute',
        bottom: '6px',
        left: '15px',
        textAlign: 'center'
      }}>
        <Typography variant="h3" sx={{
          color: 'rgb(61, 90, 128)',
          letterSpacing: '0.4px',
          fontSize: '49px'
        }}>{props.averageValue}</Typography>
        <Typography variant="caption" sx={{ letterSpacing: '0.9px', fontSize: '18px' }}>AVERAGE</Typography>
      </Box>
      <Box sx={{
        position: 'absolute',
        bottom: '6px',
        right: '15px',
        textAlign: 'center'
      }}>
        <Typography variant="h3" sx={{
          color: 'rgb(152, 193, 217)',
          letterSpacing: '0.4px',
          fontSize: '49px'
        }}>{props.currentValue}</Typography>
        <Typography variant="caption" sx={{ letterSpacing: '0.9px', fontSize: '18px' }}>CURRENT</Typography>
      </Box>
      <Box sx={{
        position: 'absolute',
        top: '6px',
        right: '15px',
        textAlign: 'left',
        color: 'rgb(238, 108, 77)'
      }}>
        <Typography variant="h3" sx={{
          letterSpacing: '0.4px',
          fontSize: '25px'
        }}>
          {changeDirection}&nbsp;{scoreChange}
        </Typography>
        <Typography variant="caption" sx={{ letterSpacing: '0.4px', fontSize: '10px' }}>CHANGE FROM<br />PREVIOUS SCORE</Typography>
      </Box>
      <Box>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </Box>
    </Box>
  );
};

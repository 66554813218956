import { createContext } from 'react';
import { createContextualCan } from '@casl/react';

import { defineAbility } from '@casl/ability';
import UserProfile from './models/UserProfile';

export const defineAbilityFor = (userProfile: UserProfile) => defineAbility(can => {
  (userProfile?.roles || []).forEach(role => {
    (role.user_features || []).forEach(userFeature => {
      can(userFeature.name, userFeature.group);
    });
  });
});

export const AbilityContext = createContext(defineAbilityFor({} as UserProfile));
export const Can = createContextualCan(AbilityContext.Consumer);

import React from "react";
import { PageLayout } from "../components/PageLayout";
import { UsersListing } from "../features/users/UsersListing";

export const UsersListingPage: React.FC = () => {
  return (
    <PageLayout>
      <UsersListing></UsersListing>
    </PageLayout>
  );
};

import React from "react";
import { PageLayout } from "../components/PageLayout";
import { Container, Divider, Typography } from "@mui/material";

export const TermsAndConditionsPage: React.FC = () => {
  return (
    <PageLayout>
      <Container maxWidth={false} sx={{ py: 5 }}>
        <Typography variant="h4" gutterBottom sx={{ flexGrow: 1 }}>
          Terms and Conditions
        </Typography>
        <Divider component="p" />
      </Container>
    </PageLayout>
  );
};

import React from "react";
import { PageLayout } from "../components/PageLayout";
import { UserForm } from "../features/users/UserForm";

export const UserProfilePage: React.FC = () => {
  return (
    <PageLayout>
      <UserForm profile={true}></UserForm>
    </PageLayout>
  );
};

import Highcharts, { Chart } from 'highcharts';
import { EXCLUDED_CHART_CLASSES } from '../../utils/constants';

export const highChartsMouseOverMethod = (context: any, event: any) => {
  Highcharts.charts.forEach((chart: Chart|undefined) => {
    if (chart && EXCLUDED_CHART_CLASSES
        .filter(exlcudedClassName => chart.container.classList.contains(exlcudedClassName))
        .length < 1) {
      const index = (context.options.x !== undefined ? context.options.x : context.index);
      chart.xAxis[0].drawCrosshair(event, chart.series[0].data[index]);
    }
  });
}

export const highChartsMouseOutMethod = (context: any, event: any) => {
  Highcharts.charts.forEach((chart: Chart|undefined) => {
    if (chart && EXCLUDED_CHART_CLASSES
        .filter(exlcudedClassName => chart.container.classList.contains(exlcudedClassName))
        .length < 1) {
      chart.xAxis[0].hideCrosshair();
    }
  });
}
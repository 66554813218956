import React from "react";
import { PageLayout } from "../components/PageLayout";
import { CompanyForm } from "../features/companies/CompanyForm";

export const CompanyProfilePage: React.FC = () => {
  return (
    <PageLayout>
      <CompanyForm profile={true}></CompanyForm>
    </PageLayout>
  );
};

import React from "react";
import { PageLayout } from "../components/PageLayout";
import { CompaniesListing } from "../features/companies/CompaniesListing";

export const CompaniesListingPage: React.FC = () => {
  return (
    <PageLayout>
      <CompaniesListing></CompaniesListing>
    </PageLayout>
  );
};

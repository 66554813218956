import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, BrowserRouter } from "react-router-dom";
import { reportWebVitals } from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import './index.css';
import { App } from './App';
import { sudxTheme } from './theme';
import store from './app/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <ThemeProvider theme={sudxTheme}>
          <Provider store={store}>
            <App /> 
          </Provider>
        </ThemeProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

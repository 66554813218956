import { AppBar, Box, Button, Container, Toolbar, Typography } from "@mui/material"

import { useEffect, useState } from "react";
import Calendar from '../../assets/Calendar.png';
import { dashboardStatusSelector, fetchedPatientsSelector, fetchPatientData, fetchPatientsAndCompounds, setDashboardDateFormat, setDashboardDateRange } from "./dashboardSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/store";
import { LoadingState } from "../../models/enums";
import { User } from "@auth0/auth0-spa-js";
import { userProfileSelector } from "../users/usersSlice";

import { SudxPatientsAutoComplete } from "../../components/common/SudxPatientsAutoComplete";
import PatientAutoComplete from "../../models/PatientAutoComplete";

export const DashboardFilter: React.FC = () => {
  const dashboardVersion = 'VERSION 1.2'
  const [dateFormat, setDateFormat] = useState<string>('appointment_interval');
  const [borderBottomAppIntval, setBorderBottomAppIntval] = useState<string>();
  const [borderBottomCal, setBorderBottomCal] = useState<string>();
  const [dateRange, setDateRange] = useState<string>('all_time');
  const [borderBottomLast6Mon, setBorderBottomLast6Mon] = useState<string>();
  const [borderBottomLast1Y, setBorderBottomLast1Y] = useState<string>();
  const [borderBottomAllTime, setBorderBottomAllTime] = useState<string>();
  const [selectedPatientValue, setSelectedPatientValue] = useState<string>('');
  const userProfile: User = useSelector(userProfileSelector);
  const patients: Array<any> = useSelector(fetchedPatientsSelector);
  const dashboardStatus: LoadingState = useSelector(dashboardStatusSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (dashboardStatus === LoadingState.Idle || dashboardStatus === LoadingState.Outdated) {
      dispatch(fetchPatientsAndCompounds({}));
    }
  }, [dashboardStatus]);

  useEffect(() => {
    if (patients && patients.length) {
      setSelectedPatientValue(patients[0].name_dob);
      dispatch(fetchPatientData({ patient_name_dob: patients[0].name_dob, location: userProfile.company.code }));
    } else {
      console.log("No patients are available, populate with No Patient Selected");
    }
  }, [patients]);

  useEffect(() => {
    if (dateFormat === 'appointment_interval') {
      setBorderBottomAppIntval('1px solid rgb(245, 249, 251)');
      setBorderBottomCal('1px solid rgb(41, 50, 65)');
    } else {
      setBorderBottomAppIntval('1px solid rgb(41, 50, 65)');
      setBorderBottomCal('1px solid rgb(245, 249, 251)');
    }
    dispatch(setDashboardDateFormat({ dateFormat }));
  }, [dateFormat])

  useEffect(() => {
    if (dateRange === 'last_6_months') {
      setBorderBottomLast6Mon('1px solid rgb(245, 249, 251)');
      setBorderBottomLast1Y('1px solid rgb(41, 50, 65)');
      setBorderBottomAllTime('1px solid rgb(41, 50, 65)');
    } else if (dateRange === 'last_1_year') {
      setBorderBottomLast6Mon('1px solid rgb(41, 50, 65)');
      setBorderBottomLast1Y('1px solid rgb(245, 249, 251)');
      setBorderBottomAllTime('1px solid rgb(41, 50, 65)');
    } else {
      setBorderBottomLast6Mon('1px solid rgb(41, 50, 65)');
      setBorderBottomLast1Y('1px solid rgb(41, 50, 65)');
      setBorderBottomAllTime('1px solid rgb(245, 249, 251)');
    }
    dispatch(setDashboardDateRange({ dateRange }));
  }, [dateRange]);

  const patientChanged = (event: { target: { value: PatientAutoComplete } }) => {
    console.log("Auto complete name dob target:", event.target.value);
    if(event.target.value){
      const name_dob: string = event.target.value.name_dob;
      setSelectedPatientValue(name_dob);
      dispatch(fetchPatientData({ patient_name_dob: name_dob, location: userProfile.company.code }));
    } else {
      console.log("Let the user select an existing patient!");
    }
  }

  return (
    <Container maxWidth={false} sx={{ padding: '0px !important;', margin: '0px !important;', position: 'relative' }}>
      <AppBar position="static">
        <Container maxWidth={false} sx={{ padding: '16.773px' }}>
          <Toolbar disableGutters sx={{ width: '100%', height: '32px', minHeight: '32px !important', fontSize: '15px', lineHeight: '21px' }}>
            <Box sx={{ flexGrow: 1, fontSize: '11px', letterSpacing: '0.55px' }}>
              {dashboardVersion}
            </Box>
            <Box>
              <Box>
                <Typography variant="caption" sx={{
                  fontSize: '11px',
                  verticalAlign: 'middle',
                  lineHeight: '32px',
                  letterSpacing: '0.55px'
                }}>Patient:&nbsp;&nbsp;&nbsp;</Typography>
                <Box sx={{ display: 'inline-block' }}>
                  <SudxPatientsAutoComplete handleChange={patientChanged} patients={patients} value={{ name_dob: selectedPatientValue }} />
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth={false} sx={{ 
        padding: '0px !important;',
        margin: '0px !important;',
        lineHeight: '40px !important',
        fontSize: '15px !important',
        color: 'rgb(245, 249, 251)',
        fontWeight: '400 !important',
        position: 'sticky !important',
        top: 0,
        zIndex: 1000,
      }}>
        <Box sx={{
          display: 'flex',
          backgroundColor: 'rgb(41, 50, 65)',
          padding: '10px 17.8px 10px 17.8px',
          height: '40px',
          verticalAlign: 'middle'
        }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="caption" sx={{
              lineHeight: '20px !important',
              fontSize: '14px !important',
              letterSpacing: '1.4px'
            }}>DATE FORMAT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
            <Button variant="text" sx={{
              lineHeight: '20px !important',
              fontSize: '14px !important',
              color: 'rgb(245, 249, 251)',
              letterSpacing: '0.7px'
            }}
            onClick={() => {setDateFormat('appointment_interval')}}>
              <Box component="img" src={Calendar} sx={{ width: '18px', verticalAlign: 'middle' }} />
              &nbsp;
              <Typography variant="caption" sx={{
                lineHeight: '20px !important',
                fontSize: '14px !important',
                borderBottom: borderBottomAppIntval
              }}>Appointment Interval</Typography>
            </Button>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Button variant="text" sx={{
              lineHeight: '20px !important',
              fontSize: '14px !important',
              color: 'rgb(245, 249, 251)',
              letterSpacing: '0.7px'
            }}
            onClick={() => {setDateFormat('calendar')}}>
              <Box component="img" src={Calendar} sx={{ width: '18px' }} />
              &nbsp;
              <Typography variant="caption" sx={{
                lineHeight: '20px !important',
                fontSize: '14px !important',
                borderBottom: borderBottomCal
              }}>Calendar</Typography>
            </Button>
          </Box>
          <Box>
            <Typography variant="caption" sx={{
              lineHeight: '20px !important',
              fontSize: '14px !important',
              letterSpacing: '1.4px'
            }}>DATE RANGE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
            <Button variant="text" sx={{
              lineHeight: '20px !important',
              fontSize: '14px !important',
              color: 'rgb(245, 249, 251)',
              letterSpacing: '0.7px'
            }}
            onClick={() => {setDateRange('last_6_months')}}>
              <Box component="img" src={Calendar} sx={{ width: '18px' }} />
              &nbsp;
              <Typography variant="caption" sx={{
                lineHeight: '20px !important',
                fontSize: '14px !important',
                borderBottom: borderBottomLast6Mon
              }}>Last 6 months</Typography>
            </Button>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Button variant="text" sx={{
              lineHeight: '20px !important',
              fontSize: '14px !important',
              color: 'rgb(245, 249, 251)',
              letterSpacing: '0.7px'
            }}
            onClick={() => {setDateRange('last_1_year')}}>
              <Box component="img" src={Calendar} sx={{ width: '18px' }} />
              &nbsp;
              <Typography variant="caption" sx={{
                lineHeight: '20px !important',
                fontSize: '14px !important',
                borderBottom: borderBottomLast1Y
              }}>Last 1 Year</Typography>
            </Button>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Button variant="text" sx={{
              lineHeight: '20px !important',
              fontSize: '14px !important',
              color: 'rgb(245, 249, 251)',
              letterSpacing: '0.7px'
            }}
            onClick={() => {setDateRange('all_time')}}>
              <Box component="img" src={Calendar} sx={{ width: '18px' }} />
              &nbsp;
              <Typography variant="caption" sx={{
                lineHeight: '20px !important',
                fontSize: '14px !important',
                borderBottom: borderBottomAllTime
              }}>All-Time</Typography>
            </Button>
          </Box>
        </Box>
      </Container>
    </Container>
  )
}
import { Grid, Box } from "@mui/material";
import { GeneralChartSection } from "./GeneralChartSection";
import { TooltipFormatterContextObject } from 'highcharts'; 
import { useSelector } from "react-redux";
import { xAxisStabilityScoresLabelsSelector, xAxisBupConcLabelsSelector, yDataBupResultsSelector, yDataNalaxoneResultsSelector, yDataNonOpioidDrugUseScoresSelector, yDataNorBupRatioResultsSelector, yDataNorBupResultsSelector, yDataOudStabilityScoresSelector, dateFormatSelector, xAxisStabilityScoresTimeSelector, xAxisBupConcTimeSelector } from "./dashboardSlice";

export const DashboardSet2: React.FC = () => {
  const xAxisStabilityScoresLabels: Array<string> = useSelector(xAxisStabilityScoresLabelsSelector);
  const xAxisStabilityScoresTime: Array<number> = useSelector(xAxisStabilityScoresTimeSelector);
  const yDataOudStabilityScores = useSelector(yDataOudStabilityScoresSelector);
  const yDataNonOpioidDrugUseScores = useSelector(yDataNonOpioidDrugUseScoresSelector);
  const xAxisBupConcLabels = useSelector(xAxisBupConcLabelsSelector);
  const xAxisBupConcTime: Array<number> = useSelector(xAxisBupConcTimeSelector);
  const yDataBupResults = useSelector(yDataBupResultsSelector);
  const yDataNorBupResults = useSelector(yDataNorBupResultsSelector);
  const yDataNalaxoneResults = useSelector(yDataNalaxoneResultsSelector);
  const yDataNorBupRatioResults = useSelector(yDataNorBupRatioResultsSelector);
  const dateFormat = useSelector(dateFormatSelector);


  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box>
          <GeneralChartSection
            title="Stability Scores"
            helpText="The Opioid Use Disorder (OUD) Stability Score quantifies the likelihood that a patient's urine tests will meet three criteria at their next appointment: a) negative results for any opioids, b) positive results for norbuprenorphine, and c) no evidence of urine adulteration.<br><br>The Non-Opioid Drug Use Score quantifies the likelihood that a patient's urine tests will test negative for all of the following non-opioid compounds: a) Cocaine, b) Amphetamines, c) Benzodiazepines, and d) Gabapentin."
            background="rgb(245, 249, 251)"
            chartOptions={{
              xAxisCategories: (dateFormat === 'calendar' ? xAxisStabilityScoresTime : xAxisStabilityScoresLabels),
              xAxisType: (dateFormat === 'calendar' ? 'datetime' : null),
              yAxisText: "",
              yAxisTickPositions: [0, 25, 50, 75, 100],
              type: 'line',
              tooltipFormatter: (context: TooltipFormatterContextObject) => {
                const point = context.point;
                const series = context.series;
                const oud = yDataOudStabilityScores;
                const nonOpioid = yDataNonOpioidDrugUseScores;
                const xIndex = isNaN(Number(point.category)) ? point.x : xAxisStabilityScoresTime.indexOf(Number(point.category));
                const categoryName = xAxisStabilityScoresLabels[xIndex];
                const value: number|string = (series.name === 'OUD Stability Score' ? oud : nonOpioid)[xIndex].toFixed(0);
                return `<p style="margin-top:0;margin-bottom:0.5em">${series.name}: ${value} ng/ml</p>
                        <p style="margin:0">Appt Date: ${categoryName}</p>`;
              },
              series: [
                {
                  name: 'OUD Stability Score',
                  type: 'line',
                  color: 'rgb(238, 108, 77)',
                  xData: (dateFormat === 'calendar' ? xAxisStabilityScoresTime : xAxisStabilityScoresLabels),
                  yData: yDataOudStabilityScores,
                  toolTipEnabled: true,
                  visible: true
                }, {
                  name: 'NON-OPIOID DRUG USE SCORE',
                  type: 'line',
                  color: 'rgb(61, 90, 128)',
                  xData: (dateFormat === 'calendar' ? xAxisStabilityScoresTime : xAxisStabilityScoresLabels),
                  yData: yDataNonOpioidDrugUseScores,
                  toolTipEnabled: true,
                  visible: true
                }
              ]
            }}
          />
        </Box>
        <Box>
          <GeneralChartSection
            title="Buprenorphine Concentration"
            paddingTop="50px"
            helpText="This panel displays the quantitative concentrations for Buprenorphine, Norbuprenorphine, and Naloxone at each patient's attended appointment. Suspected adulterated urine tests are flagged with a large red dot - these urine test values should be interpreted with caution."
            chartOptions={{
              xAxisCategories: (dateFormat === 'calendar' ? xAxisBupConcTime : xAxisBupConcLabels),
              xAxisType: (dateFormat === 'calendar' ? 'datetime' : null),
              yAxisText: "CONC (NG/ML)",
              yAxisTickPositions: [0, 1000, 2000, 3000],
              legendY: -120,
              type: 'line',
              tooltipFormatter: (context: TooltipFormatterContextObject) => {
                const point = context.point;
                const series = context.series;
                const norbup = yDataNorBupResults;
                const bup = yDataBupResults;
                const naloxone = yDataNalaxoneResults;
                const xIndex = isNaN(Number(point.category)) ? point.x : xAxisBupConcTime.indexOf(Number(point.category));
                const categoryName = xAxisBupConcLabels[xIndex];
                let ratio: string|number = (norbup[xIndex] / bup[xIndex]);
                if (isNaN(ratio)) {
                  ratio = 'N/A';
                } else {
                  ratio = ratio.toFixed(1);
                }
                if(series.name === 'NALAXONE'){
                  let value: number|string = naloxone[xIndex];
                  value = value > 3000 ? '> 3000' : (value || 0).toFixed(0);
                  return `<p style="margin-top:0;margin-bottom:0.5em">${series.name}: ${value} ng/ml</p>
                          <p style="margin:0">Appt Date: ${categoryName}</p>`;
                } else {
                  let value: number|string = (series.name === 'NORBUPRENORPHINE' ? norbup : bup)[xIndex];
                  value = value > 3000 ? '> 3000' : (value || 0).toFixed(0);
                  return `<p style="margin-top:0;margin-bottom:0.5em">${series.name}: ${value} ng/ml</p>
                          <p style="margin-top:0;margin-bottom:0.5em">Norbup/Bup Ratio: ${ratio}</p>
                          <p style="margin:0">Appt Date: ${categoryName}</p>`;
                }
              },
              series: [
                {
                  name: 'BUPRENORPHINE',
                  type: 'line',
                  color: 'rgb(238, 108, 77)',
                  xData: (dateFormat === 'calendar' ? xAxisBupConcTime : xAxisBupConcLabels),
                  yData: yDataBupResults.map(value => (value > 3000 ? 3000 : value)),
                  toolTipEnabled: true,
                  visible: true
                },
                {
                  name: 'POSS. ADULTERATION',
                  type: 'scatter',
                  color: 'rgb(255, 0, 0, 1)',
                  xData: (dateFormat === 'calendar' ? xAxisBupConcTime : xAxisBupConcLabels),
                  yData: yDataBupResults.map(value => (value > 3000 ? 3000 : null)),
                  toolTipEnabled: true,
                  linkedTo: ':previous',
                  visible: true
                }, {
                  name: 'NALAXONE',
                  type: 'line',
                  color: 'rgb(61, 90, 128)',
                  xData: (dateFormat === 'calendar' ? xAxisBupConcTime : xAxisBupConcLabels),
                  yData: yDataNalaxoneResults.map(value => (value > 3000 ? 3000 : value)),
                  toolTipEnabled: true,
                  visible: true
                }, {
                  name: 'NORBUPRENORPHINE',
                  type: 'line',
                  color: 'rgb(152, 193, 217)',
                  xData: (dateFormat === 'calendar' ? xAxisBupConcTime : xAxisBupConcLabels),
                  yData: yDataNorBupResults.map(value => (value > 3000 ? 3000 : value)),
                  toolTipEnabled: true,
                  visible: true
                }, {
                  name: 'NOR-BUP/BUP RATIO',
                  type: 'column',
                  color: 'rgba(0, 0, 0, 0.05)',
                  xData: (dateFormat === 'calendar' ? xAxisBupConcTime : xAxisBupConcLabels),
                  yData: yDataNorBupRatioResults,
                  toolTipEnabled: false,
                  visible: false
                }
              ]
            }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
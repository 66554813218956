import React from "react";
import { PageLayout } from "../components/PageLayout";
import { UsersListing } from "../features/users/UsersListing";
import { useAuth0 } from "@auth0/auth0-react";
import { Can } from "../Can";
import { Dashboard } from "../features/dashboard/Dashboard";
import { Box } from "@mui/material";

export const HomePage: React.FC = () => {
  const { user } = useAuth0();
  
  if (!user) {
    return (
      <PageLayout>
        <>
        </>
      </PageLayout>
    );
  }
  return (<PageLayout>
    <Box>
      <Can I="view_users" a="user_management">
        <UsersListing></UsersListing>
      </Can>
      <Can not I="view_users" a="user_management">
        <Dashboard />
      </Can>
    </Box>
  </PageLayout>);
}

import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/ApiResponse";
import { callSudxBackendApi, sudxBackendApiServerUrl } from "./base-api.service";
import User from "../models/User";

const usersBaseUrl = `${sudxBackendApiServerUrl}/users`;

export const getUsers = async (queryParams: { company_id?: string, page: number, ordering?: string }): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${usersBaseUrl}/`,
    method: "GET",
    params: queryParams
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getUser = async (userId: string): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${usersBaseUrl}/${userId}/`,
    method: "GET"
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const createUser = async (payload: User): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${usersBaseUrl}/`,
    method: "POST",
    data: payload
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const updateUser = async (userId: string, payload: User): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${usersBaseUrl}/${userId}/`,
    method: "PUT",
    data: payload
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const partialUpdateUser = async (userId: string, payload: User): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${usersBaseUrl}/${userId}/`,
    method: "PATCH",
    data: payload
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getUserProfile = async (): Promise<ApiResponse> => {
  console.log('5. Entering User Profile HTTP call')
  const config: AxiosRequestConfig = {
    url: `${usersBaseUrl}/profile/`,
    method: "GET"
  };
  console.log('6. User profile HTTP call - config', config)

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  console.log('7. Completed User Profile HTTP call - data and any error', data, error)

  console.log('8. Exiting User Profile HTTP call')

  return {
    data,
    error,
  };
};

export const processUserInvitation = async (payload: { invitation_code: string }): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${usersBaseUrl}/process_invitation/`,
    method: "POST",
    data: payload
  };

  const { data, error } = (await callSudxBackendApi({ config }, { skipAuthToken: true })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const updateUserPassword = async (payload: { current_password: string, new_password: string, confirm_new_password: string }): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${usersBaseUrl}/change_password/`,
    method: "PUT",
    data: payload
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

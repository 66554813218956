import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/ApiResponse";
import { callSudxBackendApi, sudxPatientApiServerUrl } from "./base-api.service";

const patientsBaseUrl = `${sudxPatientApiServerUrl}`;

export const getPatients = async (queryParams: {}): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${patientsBaseUrl}/patients`,
    method: "GET",
    params: queryParams
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const postPatientResultsViewed = async (payload: { patient_name_dob: string, location: string }): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${patientsBaseUrl}/patient-results-viewed`,
    method: "POST",
    data: payload
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};
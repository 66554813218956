import React from "react";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import Box from "@mui/material/Box/Box";

export const PageLoader: React.FC = () => {
  return (
    <Box sx={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
      <CircularProgress size={100} />
    </Box>
  );
};

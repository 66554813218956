import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { TooltipFormatterContextObject } from 'highcharts'; 
import AccessibilityModule from 'highcharts/modules/accessibility';
import more from 'highcharts/highcharts-more';
import { highChartsMouseOverMethod, highChartsMouseOutMethod } from './misc';

AccessibilityModule(Highcharts);
more(Highcharts);

export interface BarChartProps {
  xAxisCategories: Array<string|number>;
  yAxisText: string;
  type: string;
  tooltipFormatter?: Function;
  series: Array<{
    name: string,
    type?: string,
    color: string,
    xData?: Array<string|number>,
    yData?: Array<number|null|{x: number, low: number, high: number, pointWidth?: number}>,
    toolTipEnabled: boolean,
    linkedTo?: string,
    visible: boolean,
    data?: any
  }>;
}

export const BarChart: React.FC<BarChartProps> = (props: BarChartProps) => {
  const defaultTooltipFormatter: Function = (context: TooltipFormatterContextObject) => {
    const point = context.point;
    const series = context.series;
    const categoryName = series.chart.xAxis[0].categories[point.x];
    return `<p style="margin-top:0;margin-bottom:0.5em">${series.name}: ${(point.high as any).toFixed(1)}</p>
            <p style="margin:0">Appt Date: ${categoryName}</p>`;
  }

  const options = {
    chart: {
      animation: false,
      marginLeft: 80,
      style: {
        fontFamily: "SF Pro Text"
      },
      scrollablePlotArea: {
        minWidth: '1200px',
        scrollPositionX: 1
      },
      plotBackgroundColor: '#F5F9FB',
      reflow: true
    },
    credits: false,
    title: {
      text: ''
    },
    xAxis: {
      categories: props.xAxisCategories,
      lineColor: 'rgba(41, 50, 65, 0.2)',
      labels: {
        style: {
          color: "#7B828B",
          fontFamily: "SF Pro Text",
          fontSize: "12px",
          textTransform: "uppercase"
        }
      }
    },
    yAxis: {
      min: 0,
      max: 10,
      title: {
        text: props.yAxisText,
        style: {
          color: "#7B828B",
          fontFamily: "SF Pro Text",
          fontSize: "12px",
          textTransform: "uppercase"
        }
      },
      labels: {
        style: {
          color: "#7B828B",
          fontFamily: "SF Pro Text",
          fontSize: "12px",
          textTransform: "uppercase"
        }
      },
      gridLineColor: 'rgba(41, 50, 65, 0.2)'
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      floating: true,
      itemMarginTop: 3,
      itemMarginBottom: 3,
      y: -75,
      itemStyle: {
        color: "#293241",
        fontFamily: "SF Pro Text",
        fontSize: "12px",
        fontWeight: "400",
        textTransform: "uppercase"
      },
      enabled: true,
      symbolWidth: 0,
      symbolHeight: 0,
      symbolRadius: 0,
      symbolPadding: 5,
      squareSymbol: true,
      useHTML: true,
      labelFormatter: function(): any {
        return ((context: any) => {
          return `<span style="margin-left: 10px;display: inline-block; width: 9.3px; height: 9.3px; background-color: ${context.color}; transform: rotate(45deg);"></span>&nbsp;&nbsp;&nbsp;${context.name}`;
        })(this);
      }
    },
    tooltip: {
      animation: false,
      backgroundColor: "#FFF",
      borderColor: "#EAEBEC",
      borderRadius: 0,
      borderWidth: 1,
      style: {
        color: "#293241",
        fontFamily: "SF Pro Text",
        fontSize: "12px",
        fontWeight: "400"
      },
      followPointer: false,
      useHTML: true,
      formatter: function() {
        return (props.tooltipFormatter || defaultTooltipFormatter)(this);
      },
      headerFormat: "",
      hideDelay: 10000,
      shared: false,
      crosshairs: true
    },
    plotOptions: {
      series: {
        animation: false,
        point: {
          /*events: {
            mouseOver: mouseOverEvent,
            mouseOut: mouseOutEvent
          }*/
        }
      },
      columnrange: {
        borderWidth: 0,
        animation: false,
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            enabled: false
          },
          normal: {
            enabled: false
          },
          select: {
            enabled: false
          }
        }
      }
    },
    series: [...props.series.map(seriesData => {
      return {
        name: seriesData.name,
        color: seriesData.color,
        tooltip: { enabled: seriesData.toolTipEnabled },
        visible: seriesData.visible,
        enableMouseTracking: true,
        showInLegend: true,
        type: (seriesData.type || 'columnrange'),
        data: (seriesData.data || seriesData.xData?.map((value, key) => (seriesData.yData || [])[key])),
        point: {
          events: {
            mouseOver: function (event: any) {
              highChartsMouseOverMethod(this, event);
            },
            mouseOut: function (event: any) {
              highChartsMouseOutMethod(this, event);
            }
          }
        },
        events: {
          legendItemClick: function (event: any) {
            event.preventDefault();
            return false;
          }
        }
      }
    })]
  }
  return (
    <HighchartsReact sx={{ width: '100%', overflowY: 'visible !important', overflow: 'visible !important' }}
      highcharts={Highcharts}
      options={options}
      containerProps={{ style: { width: '100%', height: '100%', overflowY: 'visible !important', overflow: 'visible !important' } }}
    />
  )
}
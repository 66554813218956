import { Box, Tooltip, Typography, IconButton } from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material/";
import { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { LineChart, LineChartProps } from "./LineChart";
import { HeatMapChart, HeatMapChartProps } from "./HeatMapChart";
import { BarChart, BarChartProps } from "./BarChart";

interface GeneralChartSectionProps {
  chartOptions: LineChartProps|HeatMapChartProps|BarChartProps;
  title: string;
  helpText: string;
  background?: string;
  paddingTop?: string;
}

export const GeneralChartSection: React.FC<GeneralChartSectionProps> = ({
  chartOptions,
  title,
  helpText,
  background = 'rgba(255, 255, 255, 0)',
  paddingTop
}: GeneralChartSectionProps) => {
  const [toolTipHelper, setToolTipHelper] = useState<boolean>(false);
  const [toolTip, setToolTip] = useState<boolean>(false);
  const [expanded, setExpanded] = useState(true);

  return (
    <Box sx={{ padding: '18.6px', background: background }}>
      <Tooltip open={toolTip} placement="right" onOpen={() => {setToolTip(true)}} onClose={() => {setToolTip(false)}} title="Click icon for more information on this panel">
        <Typography variant="h2" gutterBottom sx={{
          fontSize: '20px',
          lineHeight: '20px',
          letterSpacing: '1px',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {expanded ? 
            <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} onClick={() => {setExpanded(false)}}/> 
            : 
            <KeyboardArrowRightIcon sx={{ cursor: 'pointer' }} onClick={() => {setExpanded(true)}}/> 
          }
          &nbsp;
          {title}&nbsp;
          <Tooltip open={toolTipHelper} placement="right" onClose={() => {setToolTipHelper(false)}} title={helpText}>
            <IconButton onClick={() => {setToolTipHelper(!toolTipHelper)}}>
              <InfoIcon sx={{
                color: 'rgb(152, 193, 217)',
                fontSize: '20px',
                lineHeight: '20px',
                position: 'relative',
              }}/>
            </IconButton>
          </Tooltip>
        </Typography>
      </Tooltip>
      {expanded && 
        <Box sx={{ paddingTop: (paddingTop || '18.6px') }}>
          {chartOptions.type === 'line' ? (
            <LineChart {...(chartOptions as LineChartProps)} />
          ) : chartOptions.type === 'heatmap' ? (
            <HeatMapChart {...(chartOptions as HeatMapChartProps)} />
          ) : chartOptions.type === 'bar' ? (
            <BarChart {...(chartOptions as BarChartProps)} />
          ) : null}
        </Box>
      }
    </Box>
  )
}
import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/ApiResponse";
import { callSudxBackendApi, sudxBackendApiServerUrl } from "./base-api.service";

const rolesBaseUrl = `${sudxBackendApiServerUrl}/roles`;

export const getRoles = async (): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${rolesBaseUrl}/`,
    method: "GET"
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

import { Box, Button, CircularProgress, Container, Grid, IconButton, InputAdornment, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Moment from 'react-moment';
import { Link, useParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { PAGINATION_PER_PAGE } from "../../utils/constants";
import { buildOrdering } from "../../utils/misc";
import { useSelector } from "react-redux";
import { LoadingState } from "../../models/enums";
import { fetchUsers, fetchedUsersSelector, usersCountSelector, usersStatusSelector, updateUserStatus, fetchRoles, fetchingRolesSelector, rolesSelector } from "./usersSlice";
import UsersQueryParams from "../../models/UsersQueryParams";
import { useAppDispatch } from "../../app/store";
import { SudxTableSortHeaderCell } from "../../components/common/SudxTableSortHeaderCell";
import User from "../../models/User";
import { Can } from "../../Can";
import { Menu, Search } from "@mui/icons-material";
import Role from "../../models/Role";
import { AbilityContext } from "../../Can";

export const UsersListing: React.FC = () => {
  const ability = useContext(AbilityContext);
  const dispatch = useAppDispatch();
  const users: Array<User> = useSelector(fetchedUsersSelector);
  const usersStatus: LoadingState = useSelector(usersStatusSelector);
  const usersCount: number = useSelector(usersCountSelector);
  const { companyId } = useParams();
  const [searchValue, setSearchValue] = useState<string>();
  const [filterRoleId, setFilterRoleId] = useState<string|undefined>();
  const [page, setPage] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<string>();
  const [sortOrderDirection, setSortOrderDirection] = useState<'asc'|'desc'>('asc');
  const fetchingRoles: LoadingState = useSelector(fetchingRolesSelector);
  const roles: Array<Role> = useSelector(rolesSelector);
  console.log('Reloading component')
  useEffect(() => {
    if (fetchingRoles === LoadingState.Idle) {
      dispatch(fetchRoles());
    }
  }, []);

  const filterRoleChanged = (event: any) => {
    console.log('Setting role filter field', event.target.value);
    setFilterRoleId(event.target.value);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleSortChange = (orderField: string) => {
    if (sortOrder === orderField) {
      console.log('Order direction', sortOrderDirection === 'asc' ? 'desc' : 'asc');
      setSortOrderDirection(sortOrderDirection === 'asc' ? 'desc' : 'asc');
    } else {
      console.log('Order direction', 'asc');
      setSortOrderDirection('asc');
    }
    console.log('Setting order field', sortOrder, orderField);
    setSortOrder(orderField);
  };

  const toggleStatus = async (user: User) => {
    dispatch(updateUserStatus({ id: user.id as string, user: { is_active: !user.is_active as boolean } as User }));
  };

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleSearchKeyUp = (event: any) => {
    if (event.key === 'Enter') {
      setSearchValue(event.target.value);
      loadUsers(true);
    }
  };

  const loadUsers = async (searchUpdated: boolean = false) => {
    console.log(usersStatus);
    if (usersStatus === LoadingState.Loading) {
      return;
    }

    if (searchUpdated && page !==0) {
      setPage(0);
      return;
    }

    const queryParams: UsersQueryParams = {
      page: (page + 1)
    };
    if (companyId) {
      queryParams.company_id = companyId
    }
    if (searchValue) {
      queryParams.search = searchValue;
    }
    if (filterRoleId) {
      queryParams.roles__id = filterRoleId;
    }
    const orderingFields: Array<any> = [];
    if (sortOrder) {
      orderingFields.push({
        name: sortOrder,
        order: sortOrderDirection
      });
    }
    queryParams.ordering = buildOrdering(orderingFields);
    console.log('Query params', queryParams, filterRoleId, searchValue, sortOrder);
    dispatch(fetchUsers(queryParams));
  };

  useEffect(() => {
    console.log('Load users called');
    loadUsers();
  }, [page, sortOrder, sortOrderDirection, filterRoleId]);

  return (
    <Container maxWidth={false} sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom sx={{ flexGrow: 1 }}>
        User Management
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <hr />
          <Typography variant="h6" gutterBottom sx={{ flexGrow: 1, py: 0 }}>
            Filter By
          </Typography>
          {fetchingRoles === LoadingState.Loading ?
          <Box sx={{ py: 3, textAlign: 'center' }}>
            <CircularProgress size={50} />
          </Box>
          :
          <>
            <Box sx={{ py: 5 }}>
              <Typography variant="caption">Search</Typography>
              <TextField
                variant="filled"
                placeholder="Name or Company"
                onChange={handleSearchChange}
                value={searchValue}
                className="Search-field"
                sx={{ width: '100%' }}
                onKeyUp={handleSearchKeyUp}
                InputProps={{
                  autoComplete: 'off',
                  startAdornment:
                    <InputAdornment position="start">
                      <Menu />
                    </InputAdornment>,
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton onClick={() => { loadUsers(true) }} sx={{ maxBlockSize: 20 }}>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </Box>
            <Box sx={{ py: 3 }}>
              <Typography variant="caption">Role</Typography>
              <TextField
                variant="filled"
                className="Filter-field"
                placeholder="Filter by Role"
                sx={{ width: '100%' }}
                select
                value={filterRoleId || ''}
                onChange={filterRoleChanged}
              >
                {roles.map(role => (
                  <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                ))}
              </TextField>
            </Box>
          </>
          }
        </Grid>
        <Grid item md={9}>
          <hr />
          <Box sx={{ display: 'flex', mb: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ flexGrow: 1 }}>
              {usersCount} Users
            </Typography>
            <Can I="add_user" a="user_management">
              <Button
                variant="contained"
                component={Link}
                to="/users/new"
                sx={{ maxBlockSize: 50 }}
              >
                <AddIcon />
                &nbsp;Add User
              </Button>
            </Can>
          </Box>
          <TableContainer component={Paper} elevation={0}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <SudxTableSortHeaderCell
                    cellKey="email"
                    sortOrder={sortOrder}
                    sortDirection={sortOrderDirection}
                    onSortClick={handleSortChange}
                  >
                    Email
                  </SudxTableSortHeaderCell>
                  <SudxTableSortHeaderCell
                    cellKey="first_name"
                    sortOrder={sortOrder}
                    sortDirection={sortOrderDirection}
                    onSortClick={handleSortChange}
                  >
                    First Name
                  </SudxTableSortHeaderCell>
                  <SudxTableSortHeaderCell
                    cellKey="last_name"
                    sortOrder={sortOrder}
                    sortDirection={sortOrderDirection}
                    onSortClick={handleSortChange}
                  >
                    Last Name
                  </SudxTableSortHeaderCell>
                  <Can I="view_companies" a="company_management">
                    <SudxTableSortHeaderCell
                      cellKey="company__name"
                      sortOrder={sortOrder}
                      sortDirection={sortOrderDirection}
                      onSortClick={handleSortChange}
                    >
                      Company
                    </SudxTableSortHeaderCell>
                  </Can>
                  <SudxTableSortHeaderCell
                    cellKey="title"
                    sortOrder={sortOrder}
                    sortDirection={sortOrderDirection}
                    onSortClick={handleSortChange}
                  >
                    Title
                  </SudxTableSortHeaderCell>
                  <SudxTableSortHeaderCell
                    cellKey="updated_at"
                    sortOrder={sortOrder}
                    sortDirection={sortOrderDirection}
                    onSortClick={handleSortChange}
                  >
                    Last Updated
                  </SudxTableSortHeaderCell>
                  <SudxTableSortHeaderCell
                    cellKey="roles__name"
                    sortOrder={sortOrder}
                    sortDirection={sortOrderDirection}
                    onSortClick={handleSortChange}
                  >
                    Role
                  </SudxTableSortHeaderCell>
                  <Can I="edit_user_status" a="user_management">
                    <SudxTableSortHeaderCell
                      cellKey="is_active"
                      sortOrder={sortOrder}
                      sortDirection={sortOrderDirection}
                      onSortClick={handleSortChange}
                      sx={{ textAlign: 'center' }}
                    >
                      Status
                    </SudxTableSortHeaderCell>
                  </Can>
                  <Can I="edit_user" a="user_management">
                    <TableCell
                      sx={{ textAlign: 'center' }}
                    >Edit</TableCell>
                  </Can>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow
                    key={user.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.first_name}</TableCell>
                    <TableCell>{user.last_name}</TableCell>
                    <Can I="view_companies" a="company_management">
                      <TableCell>{user.company?.name}</TableCell>
                    </Can>
                    <TableCell>{user.title}</TableCell>
                    <TableCell>
                      <Moment format="MM/DD/YYYY">
                        {user.updated_at}
                      </Moment>
                    </TableCell>
                    <TableCell>{user.role_names}</TableCell>
                    <Can I="edit_user_status" a="user_management">
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Button 
                          color="secondary" 
                          variant="outlined" 
                          onClick={() => toggleStatus(user)}
                          disabled={!ability.can('edit_user_status', 'user_management')}
                        >
                          {user.is_active ? 'Active' : 'Inactive'}
                        </Button>
                      </TableCell>
                    </Can>
                    <Can I="edit_user" a="user_management">
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Button variant="outlined" component={Link} to={"/users/" + user.id + "/edit"}>
                          Edit
                        </Button>
                      </TableCell>
                    </Can>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={usersCount}
                    rowsPerPage={PAGINATION_PER_PAGE}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPageOptions={[]}
                  ></TablePagination>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

import moment from 'moment-timezone';
import { SUBSTANCE_USE_HEAT_MAP_LABELS } from '../../utils/constants';
import DashboardState from "../../models/DashboardState";

export const normaliseChartData = (state: DashboardState) => {
  const dateRangeFilter: Function = (state.dateRange === 'last_6_months' ? 
    (dateValue: string) => (moment().subtract(6, 'month').diff(moment(dateValue)) <= 0) : 
      (state.dateRange === 'last_1_year' ? 
        (dateValue: string) => (moment().subtract(1, 'year').diff(moment(dateValue)) <= 0) : 
        (dateValue: string) => true));
  if (state.stabilityScores && state.stabilityScores.length) {
    const newXAxisLabels: Array<string> = [];
    const newXAxisTime: Array<number> = [];
    const newYDataOudStabilityScores: Array<number> = [];
    const newYDataNonOpioidDrugUseScores: Array<number> = [];
    state.stabilityScores.filter(score => dateRangeFilter(score.date)).forEach((score: any) => {
      newXAxisLabels.push(moment(score.date).format("MMM DD 'YY").toUpperCase());
      newXAxisTime.push(moment(score.date).valueOf());
      newYDataOudStabilityScores.push(Math.round((score.pred_adherence || 0) * 100));
      newYDataNonOpioidDrugUseScores.push(Math.round((score.pre_drug_use || 0) * 100));
    });
    state.xAxisStabilityScoresLabels = newXAxisLabels;
    state.xAxisStabilityScoresTime = newXAxisTime;
    state.yDataOudStabilityScores = newYDataOudStabilityScores;
    state.yDataNonOpioidDrugUseScores = newYDataNonOpioidDrugUseScores;
  } else {
    state.xAxisStabilityScoresLabels = [];
    state.xAxisStabilityScoresTime = [];
    state.yDataOudStabilityScores = [];
    state.yDataNonOpioidDrugUseScores = [];
  }
  if (state.labOrderedTestResults && state.labOrderedTestResults.length) {
    const filteredLabOrderedTestResults = state.labOrderedTestResults.filter(labTest => dateRangeFilter(labTest.date_2));
    state.yDataBupResults = filteredLabOrderedTestResults.filter(labTest => labTest.lab_test_name === 'Buprenorphine').map(labTest => Math.round(labTest.result));
    state.yDataNorBupResults = filteredLabOrderedTestResults.filter(labTest => labTest.lab_test_name === 'Norbuprenorphine').map(labTest => Math.round(labTest.result));
    state.yDataNalaxoneResults = filteredLabOrderedTestResults.filter(labTest => labTest.lab_test_name === ' Collect Dt').map(labTest => Math.round(labTest.result));
    state.xAxisBupConcLabels = filteredLabOrderedTestResults.filter(labTest => labTest.lab_test_name === 'Buprenorphine').map(labTest => moment(labTest.date_2).format("MMM DD 'YY").toUpperCase());
    state.xAxisBupConcTime = filteredLabOrderedTestResults.filter(labTest => labTest.lab_test_name === 'Buprenorphine').map(labTest => moment(labTest.date_2).valueOf());
    const norBupRatioResults: Array<number> = state.yDataBupResults.map((result: number, key: number) => Math.round(state.yDataNorBupResults[key] / result));
    const minRatio = Math.min(...norBupRatioResults);
    const maxRatio = Math.max(...norBupRatioResults);
    state.yDataNorBupRatioResults = norBupRatioResults.map(ratio => ((ratio - minRatio) * 3000 / (maxRatio - minRatio)));
    state.yDataBupHistoryResults = filteredLabOrderedTestResults.filter(labTest => labTest.lab_test_name === 'Buprenorphine').map(labTest => (labTest.result_flag === 1 ? 1 : -1));
    state.yDataNorBupHistoryResults = filteredLabOrderedTestResults.filter(labTest => labTest.lab_test_name === 'Norbuprenorphine').map(labTest => (labTest.result_flag === 1 ? 1 : -1));
    state.xAxisBupHistoryLabels = filteredLabOrderedTestResults.filter(labTest => labTest.lab_test_name === 'Buprenorphine').map(labTest => moment(labTest.date_2).format("MMM DD 'YY").toUpperCase());
    const substanceUseHeatmapData: number[][] = Array.from(Array(SUBSTANCE_USE_HEAT_MAP_LABELS.length)).map(_ => Array.from(Array(state.xAxisBupHistoryLabels.length)).map(_ => 0));
    const substanceUseHeatmapDataState: number[][] = Array.from(Array(SUBSTANCE_USE_HEAT_MAP_LABELS.length)).map(_ => Array.from(Array(state.xAxisBupHistoryLabels.length)).map(_ => 0));
    const substanceDetailHeatmap: number[][][] = Array.from(Array(state.xAxisBupHistoryLabels.length)).map(_ => []);
    const substanceDetailHeatmapData: any[][] = Array.from(Array(state.xAxisBupHistoryLabels.length)).map(_ => []);
    const substanceDetailHeatMapColorAxis: any = {};
    const substanceUseLabOrderedTestResults = state.labOrderedTestResults.filter(labTest => dateRangeFilter(labTest.date_2)).filter((labOrderedTestResult: any) => {
      // TODO: Move the excluded lab tests to a constant array and use that to perform an exclusion here
      return labOrderedTestResult.lab_test_name !== ' Collect Dt'
    });
    for (const labTest of substanceUseLabOrderedTestResults) {
      if (['Buprenorphine', 'Norbuprenorphine'].indexOf(labTest.lab_test_name) > -1) {
        continue;
      }
      if (state.heatmapReverse[labTest.lab_test_name]) {
        const heatmapDataIndex: number = state.heatmapCompounds[state.heatmapReverse[labTest.lab_test_name]].index;
        const xAxisKey: number = state.xAxisBupHistoryLabels.indexOf(moment(labTest.date_2).format("MMM DD 'YY").toUpperCase());
        if (xAxisKey > -1) {
          if (labTest.ordered !== 0) {
            substanceUseHeatmapData[heatmapDataIndex][xAxisKey] += labTest.result;
          }
          const currentStateValue: number = substanceUseHeatmapDataState[heatmapDataIndex][xAxisKey];
          if (currentStateValue !== 1) {
            if (!(currentStateValue === -1 && labTest.ordered === 0)) {
              substanceUseHeatmapDataState[heatmapDataIndex][xAxisKey] = (labTest.ordered === 0 ? 0 : (labTest.result_flag ? 1 : -1));
            }
          }
        }
      }
      if (labTest.ordered !== 0 && labTest.result_flag === 1 && ['Buprenorphine', 'Norbuprenorphine'].indexOf(labTest.lab_test_name) < 0) {
        const xAxisKey: number = state.xAxisBupHistoryLabels.indexOf(moment(labTest.date_2).format("MMM DD 'YY").toUpperCase());
        if (xAxisKey > -1) {
          if (!substanceDetailHeatMapColorAxis[labTest.lab_test_name]) {
            substanceDetailHeatMapColorAxis[labTest.lab_test_name] = {
              from: Object.keys(substanceDetailHeatMapColorAxis).length * 2 + 2.1,
              to: Object.keys(substanceDetailHeatMapColorAxis).length * 2 + 4,
              name: labTest.lab_test_name,
              color: (state.compounds.filter(compound => compound.dashboard_name === labTest.lab_test_name)[0]?.main_color || 'rgb(238, 108, 77)')
            };
          }
          substanceDetailHeatmap[xAxisKey].push([xAxisKey, 0, labTest.result, substanceDetailHeatMapColorAxis[labTest.lab_test_name].from + 0.4, labTest.lab_test_name]);
        }
      }
    }
    state.yDataSubstanceUseHeatmap = substanceUseHeatmapData;
    state.yDataSubstanceUseHeatmapState = substanceUseHeatmapDataState;
    state.colorAxisSubstanceDetailHeatmap = [{
      from: -1,
      to: 2,
      color: 'rgba(0, 0, 0, 0)',
      name: 'Transparent Placeholder',
    }].concat(Object.keys(substanceDetailHeatMapColorAxis).map(compound_name => substanceDetailHeatMapColorAxis[compound_name]));
    state.yDataSubstanceDetailHeatmap = substanceDetailHeatmap.map((columnData: number[][], xAxisKey: number) => {
      const newColumnData: number[][] = columnData.map((heatmapCoordinates: number[], yAxisKey: number) => {
        substanceDetailHeatmapData[xAxisKey].push({
          name: heatmapCoordinates[4],
          value: heatmapCoordinates[2]
        })
        return [xAxisKey, yAxisKey, heatmapCoordinates[3]];
      });
      return (!newColumnData.length ? [[xAxisKey, 0, 0]] : newColumnData);
    });
    state.yDataSubstanceDetailHeatmapData = substanceDetailHeatmapData;
  } else {
    state.xAxisBupConcLabels = [];
    state.xAxisBupConcTime = [];
    state.yDataBupResults = [];
    state.yDataNorBupResults = [];
    state.yDataNorBupRatioResults = [];
    state.yDataNalaxoneResults = [];
    state.xAxisBupHistoryLabels = [];
    state.yDataBupResults = [];
    state.yDataNorBupResults = [];
    state.yDataSubstanceUseHeatmap = [];
    state.colorAxisSubstanceDetailHeatmap = [];
    state.yDataSubstanceDetailHeatmap = [];
    state.yDataSubstanceDetailHeatmapData = [];
  }
  if (state.norbupConcRiskScores && state.norbupConcRiskScores.length) {
    const filteredNorbupConcRiskScores = state.norbupConcRiskScores.filter(score => dateRangeFilter(score.score_date));
    state.xAxisNorBupConcLabels = filteredNorbupConcRiskScores.map(score => moment(score.score_date).format("MMM DD 'YY"));
    state.yDataNorBupConcQuantAllDose = filteredNorbupConcRiskScores.map(score => score.quantile_all_dose);
  } else {
    state.xAxisNorBupConcLabels = []
    state.yDataNorBupConcQuantAllDose = []
  }
}
  
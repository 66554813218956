import { Button, SxProps } from "@mui/material";
import { MouseEventHandler, ReactNode } from "react";
import { Link } from "react-router-dom";
interface SudxNavBarButtonProps {
  linkTo: string
  onButtonClick: MouseEventHandler<HTMLAnchorElement>,
  children: ReactNode,
  sx?: SxProps
}

export const SudxNavBarButton: React.FC<SudxNavBarButtonProps> = (props: SudxNavBarButtonProps) => {
  return (
    <Button
      onClick={props.onButtonClick}
      component={Link}
      to={props.linkTo}
      sx={Object.assign({ my: 2, color: '#000000' }, props.sx || {})}
    >
      {props.children}
    </Button>
  )
}
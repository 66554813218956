import { Box, Tooltip, Typography, IconButton } from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material/";
import { useState } from "react";
import { AverageCurrentScoreChart } from "./AverageCurrentScoreChart";

interface AverageCurrentScoreProps {
  averageValue: number;
  currentValue: number;
  changeValue: number;
  title: string;
  helpText: string;
}

export const AverageCurrentScore: React.FC<AverageCurrentScoreProps> = (props: AverageCurrentScoreProps) => {
  const [scoreToolTipHelper, setScoreToolTipHelper] = useState<boolean>(false);
  const [scoreToolTip, setScoreToolTip] = useState<boolean>(false);

  return (
    <Box>
      <Tooltip open={scoreToolTipHelper} placement="right" onOpen={() => {setScoreToolTipHelper(true)}} onClose={() => {setScoreToolTipHelper(false)}} title="Click icon for more information on this panel">
        <Typography variant="h2" gutterBottom sx={{
          fontSize: '20px',
          lineHeight: '20px',
          letterSpacing: '1px',
          display: 'inline-block'
        }}>
          {props.title}&nbsp;
          <Tooltip open={scoreToolTip} placement="right" onClose={() => {setScoreToolTip(false)}} title={props.helpText}>
            <IconButton onClick={() => {setScoreToolTip(!scoreToolTip)}}>
              <InfoIcon sx={{
                color: 'rgb(152, 193, 217)',
                fontSize: '20px',
                lineHeight: '20px',
                position: 'relative',
              }}/>
            </IconButton>
          </Tooltip>
        </Typography>
      </Tooltip>
      <Box>
        <AverageCurrentScoreChart averageValue={props.averageValue} currentValue={props.currentValue} changeValue={props.changeValue} />
      </Box>
    </Box>
  )
}
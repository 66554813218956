import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/ApiResponse";
import { callSudxBackendApi, sudxBackendApiServerUrl } from "./base-api.service";

import CompanyQueryParams from '../models/CompaniesQueryParams';
import Company from "../models/Company";

const companiesBaseUrl = `${sudxBackendApiServerUrl}/companies`;

export const getCompanies = async (queryParams: CompanyQueryParams): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${companiesBaseUrl}/`,
    method: "GET",
    params: queryParams
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const companiesAutoComplete = async (queryParams: { search?: string }): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${companiesBaseUrl}/auto_complete`,
    method: "GET",
    params: queryParams
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getCompany = async (companyId: string): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${companiesBaseUrl}/${companyId}`,
    method: "GET"
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const getCompanyProfile = async (): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${companiesBaseUrl}/profile/`,
    method: "GET"
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const createCompany = async (payload: Company): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${companiesBaseUrl}/`,
    method: "POST",
    data: payload
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const updateCompany = async (companyId: string, payload: Company): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${companiesBaseUrl}/${companyId}/`,
    method: "PUT",
    data: payload
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const partialUpdateCompany = async (companyId: string, payload: Company): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${companiesBaseUrl}/${companyId}/`,
    method: "PATCH",
    data: payload
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

import { Button, SxProps } from "@mui/material";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

export const SudxBackButton: React.FC<{ children: ReactNode, sx?: SxProps }> = (props: { children: ReactNode, sx?: SxProps }) => {
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      onClick={() => {navigate(-1)}}
      sx={props.sx || {}}
    >
      {props.children}
    </Button>
  )
}
import { Box, Button, Container, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../app/store";
import { LoadingState } from "../../models/enums";
import { useSelector } from "react-redux";
import { fetchCompany, fetchingCompanyStatusSelector, formCompanySelector, saveCompany, savingCompanyStatusSelector, setFormNewCompany, fetchingCompanyProfileSelector, companyProfileSelector, fetchCompanyProfile, setSaveCompanyStatus } from "./companiesSlice";
import Company from "../../models/Company";
import { SudxBackButton } from "../../components/common/SudxBackButton";
import { FieldValues, useForm } from "react-hook-form";
import { PageLoader } from "../../components/PageLoader";
import { fetchUserProfile, setSnackBar } from "../users/usersSlice";
import { fetchPatientsAndCompounds } from "../dashboard/dashboardSlice";

export const CompanyForm: React.FC<{ profile?: boolean }> = (props: { profile?: boolean }) => {
  const dispatch = useAppDispatch();
  const savingCompanyStatus: LoadingState = useSelector(savingCompanyStatusSelector);
  const formCompany: Company = useSelector(formCompanySelector);
  const fetchingCompanyStatus: LoadingState = useSelector(fetchingCompanyStatusSelector);
  const fetchingCompanyProfile: LoadingState = useSelector(fetchingCompanyProfileSelector);
  const companyProfile: Company = useSelector(companyProfileSelector);
  const navigate = useNavigate();
  
  const { companyId } = useParams();
  const [savingCompany, setSavingCompany] = useState<boolean>(false);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [loadingForm, setLoadingForm] = useState<boolean>(false);

  useEffect(() => {
    setLoadingForm(!!companyId && (fetchingCompanyStatus === LoadingState.Loading))
  }, [fetchingCompanyStatus, companyId, fetchingCompanyProfile]);

  useEffect(() => {
    if (props.profile) {
      dispatch(fetchCompanyProfile());
    }
  }, []);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchCompany(companyId));
    } else {
      dispatch(setFormNewCompany());
    }
  }, [companyId]);

  useEffect(() => {
    if (!props.profile) {
      ['name', 'phone_number', 'street_address', 'city', 'state', 'zip_code', 'code', 'primary_contact_first_name', 'primary_contact_last_name', 'primary_contact_email', 'primary_contact_phone_number']
        .forEach(fieldName => {
          setValue(fieldName, formCompany[fieldName]);
        });
    }
  }, [formCompany]);

  useEffect(() => {
    if (props.profile) {
      ['name', 'phone_number', 'street_address', 'city', 'state', 'zip_code', 'code', 'primary_contact_first_name', 'primary_contact_last_name', 'primary_contact_email', 'primary_contact_phone_number']
        .forEach(fieldName => {
          setValue(fieldName, companyProfile[fieldName]);
        });
    }
  }, [companyProfile]);

  useEffect(() => {
    if (savingCompanyStatus === LoadingState.Loading) {
      setSavingCompany(true);
    } else {
      setSavingCompany(false);
    }

    if (savingCompanyStatus === LoadingState.Succeeded) {
      console.log('Showing snackbar')
      console.log('Saving company status', savingCompanyStatus);
      dispatch(setSaveCompanyStatus({ savingCompanyStatus: LoadingState.Idle }));
      dispatch(setSnackBar({ snackBarType: 'success', snackBarText: (props.profile ? 'Company updated' : (companyId ? 'Company updated' : 'Company created')) }));
      dispatch(fetchUserProfile());
      dispatch(fetchPatientsAndCompounds({}));
      if (props.profile) {
        navigate('/');
      } else {
        navigate('/companies');
      }
    }
  }, [savingCompanyStatus]);

  const dispatchSaveCompany = async (data: Company) => {
    dispatch(saveCompany({ id: (props.profile ? companyProfile.id : companyId), company: data }));
  }

  const onSubmit = (data: FieldValues) => {
    dispatchSaveCompany(data as Company);
  }

  return (
    loadingForm ?
      <PageLoader /> : 
      <Container sx={{ py: 5, maxWidth: 900, width: 900 }}>
        <Box sx={{ display: 'flex', mb: 3 }}>
          <Typography variant="h4" gutterBottom sx={{ flexGrow: 1 }}>
            {companyId ? 'Company' : props.profile ? 'Manage Company' : 'New Company' }
          </Typography>
          { companyId && 
            <Button
              variant="contained"
              component={Link}
              to={`/companies/${companyId}/users`}
              sx={{ maxBlockSize: 50 }}
            >
              View Users
            </Button>
          }
        </Box>
        <Typography variant="h6" gutterBottom sx={{ my: 2 }}>
          Company Details
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Company Name"
                  disabled={savingCompany}
                  sx={{ width: '100%' }}
                  error={!!errors.name}
                  helperText={errors.name?.message as string}
                  {...register("name", { required: "Name is required" })}
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Phone Number"
                  disabled={savingCompany}
                  sx={{ width: '100%' }}
                  {...register("phone_number")}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Street Address"
                  disabled={savingCompany}
                  sx={{ width: '100%' }}
                  {...register("street_address")}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="City"
                  disabled={savingCompany}
                  sx={{ width: '100%' }}
                  {...register("city")}
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="State"
                  disabled={savingCompany}
                  sx={{ width: '100%' }}
                  {...register("state")}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Zip Code"
                  disabled={savingCompany}
                  sx={{ width: '100%' }}
                  {...register("zip_code")}
                />
              </Box>
            </Grid>
          </Grid>
          <Typography variant="h6" gutterBottom sx={{ my: 2 }}>
            Company Code (LIS Mapping)
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Provider Sample Identifier Code"
                  disabled={savingCompany}
                  sx={{ width: '100%' }}
                  error={!!errors.code}
                  helperText={errors.code?.message as string}
                  {...register("code", { required: "Code is required" })}
                />
              </Box>
            </Grid>
          </Grid>
          <Typography variant="h6" gutterBottom sx={{ my: 2 }}>
            Primary Contact Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="First Name"
                  disabled={savingCompany}
                  sx={{ width: '100%' }}
                  {...register("primary_contact_first_name")}
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Last Name"
                  disabled={savingCompany}
                  sx={{ width: '100%' }}
                  {...register("primary_contact_last_name")}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Email"
                  disabled={savingCompany}
                  sx={{ width: '100%' }}
                  {...register("primat_contact_email")}
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Phone Number"
                  disabled={savingCompany}
                  sx={{ width: '100%' }}
                  {...register("primary_contact_phone_number")}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ pb: 2, pt: 5, display: 'flex' }}>
            <Button
              disabled={savingCompany}
              variant="contained"
              type="submit"
              sx={{blockSize: 52, px: 4, minWidth: 230}}
            >
              Save Changes
            </Button>
            <Box sx={{ px: 1 }} />
            <SudxBackButton
              sx={{blockSize: 52, px: 4, minWidth: 230}}
            >
              Cancel
            </SudxBackButton>
          </Box>
        </form>
      </Container>
  );
};

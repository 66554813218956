import React, { useState } from "react";
import { PageLayout } from "../components/PageLayout";
import { Box, Button, Container, Grid, TextField, Typography } from "@mui/material";

import { updateUserPassword } from '../services/user.service'
import { SudxBackButton } from "../components/common/SudxBackButton";
import { useAuth0 } from "@auth0/auth0-react";

export const ChangePasswordPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const [changingPassword, setChangingPassword] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');

  const currentPasswordChanged = (event: any) => {
    setCurrentPassword(event.target.value);
  }

  const newPasswordChanged = (event: any) => {
    setNewPassword(event.target.value);
  }

  const confirmNewPasswordChanged = (event: any) => {
    setConfirmNewPassword(event.target.value);
  }

  const changePassword = () => {
    updateUserPassword({
      current_password: currentPassword,
      new_password: newPassword,
      confirm_new_password: confirmNewPassword
    }).then(response => {
      console.log('Password changed', response);
      loginWithRedirect({
          appState: {
            returnTo: "/",
          },
          authorizationParams: {
            prompt: "login",
          },
        });
    }).catch(err => {
      console.error('Error changing password', err)
    })
  }

  return (
    <PageLayout>
      <Container sx={{ py: 5, maxWidth: 560, width: 560 }}>
        <Typography variant="h4" gutterBottom sx={{ flexGrow: 1, py: 2 }}>
          Change Password
        </Typography>
        <Grid container spacing={2}>
          <Grid item>
            <Box sx={{ mb: 2 }}>
              <TextField
                label="Current Password"
                disabled={changingPassword}
                sx={{ minWidth: 250 }}
                value={currentPassword}
                type="password"
                onChange={currentPasswordChanged}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Box sx={{ mb: 2 }}>
              <TextField
                label="New Password"
                disabled={changingPassword}
                sx={{ minWidth: 250 }}
                value={newPassword}
                type="password"
                onChange={newPasswordChanged}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Box sx={{ mb: 2 }}>
              <TextField
                label="Confirm New Password"
                disabled={changingPassword}
                sx={{ minWidth: 250 }}
                value={confirmNewPassword}
                type="password"
                onChange={confirmNewPasswordChanged}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ py: 2, display: 'flex' }}>
          <Button
            disabled={changingPassword}
            variant="contained"
            onClick={changePassword}
          >
            Change Password
          </Button>
          <Box sx={{ px: 1 }} />
          <SudxBackButton>
            Cancel
          </SudxBackButton>
        </Box>
      </Container>
    </PageLayout>
  );
};

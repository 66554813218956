import { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/ApiResponse";
import { callSudxBackendApi, sudxPatientApiServerUrl } from "./base-api.service";

const chartDataBaseUrl = `${sudxPatientApiServerUrl}/chart-data`;

export const getCompounds = async (queryParams: {}): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${chartDataBaseUrl}/compounds`,
    method: "GET",
    params: queryParams
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const loadStabilityScores = async (payload: { patient_name_dob: string, location: string }): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${chartDataBaseUrl}/stability-scores`,
    method: "POST",
    data: payload
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const loadLabOrderedTestResults = async (payload: { patient_name_dob: string, location: string }): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${chartDataBaseUrl}/lab-ordered-test-results`,
    method: "POST",
    data: payload
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const loadNorbupConcRiskScores = async (payload: { patient_name_dob: string, location: string }): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${chartDataBaseUrl}/norbuprenorphine-concentration-risk-scores`,
    method: "POST",
    data: payload
  };

  const { data, error } = (await callSudxBackendApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

import { Snackbar, Alert, AlertColor } from "@mui/material";
import React, { useEffect } from "react";
import { setSnackBar, snackBarTextSelector, snackBarTypeSelector } from "./usersSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/store";

export const GlobalSnackBar: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const snackBarText: string | undefined = useSelector(snackBarTextSelector);
  const snackBarType: string | undefined = useSelector(snackBarTypeSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (snackBarText) {
      handleClick();
    }
  }, [snackBarText, snackBarType])
  
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    if (snackBarText) {
      dispatch(setSnackBar({ snackBarType: 'info', snackBarText: '' }));
    }
  };

  return(
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={handleClose} severity={snackBarType as AlertColor} sx={{ width: '100%' }}>
        {snackBarText}
      </Alert>
    </Snackbar>
  )
}
import { Box, Button, Container, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { PAGINATION_PER_PAGE } from "../../utils/constants";
import { useAppDispatch } from "../../app/store";
import { companiesCountSelector, companiesStatusSelector, fetchCompanies, fetchedCompaniesSelector, savingCompanyStatusSelector, updateCompanyStatus } from "./companiesSlice";
import { useSelector } from "react-redux";
import { LoadingState } from "../../models/enums";
import Company from "../../models/Company";
import { buildOrdering } from "../../utils/misc";
import { SudxTableSortHeaderCell } from "../../components/common/SudxTableSortHeaderCell";
import { Can } from "../../Can";
import Menu from "@mui/icons-material/Menu";
import { Search } from "@mui/icons-material";

export const CompaniesListing: React.FC = () => {
  const dispatch = useAppDispatch();
  const companies: Array<Company> = useSelector(fetchedCompaniesSelector);
  const companiesStatus: LoadingState = useSelector(companiesStatusSelector);
  const companiesCount: number = useSelector(companiesCountSelector);
  const [page, setPage] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<string>();
  const [sortOrderDirection, setSortOrderDirection] = useState<'asc'|'desc'>('asc');
  const [searchValue, setSearchValue] = useState<string>();

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    console.log('Handle page change')
    setPage(newPage);
  };

  const handleSearchChange = (event: any) => {
    console.log('Search value', event.target.value)
    setSearchValue(event.target.value);
  };

  const handleSearchKeyUp = (event: any) => {
    console.log('Search key up event', event);
    console.log('Search value', event.target.value)
    if (event.key === 'Enter') {
      setSearchValue(event.target.value);
      loadCompanies(true);
    }
  };

  const handleSortChange = (orderField: string) => {
    if (sortOrder === orderField) {
      setSortOrderDirection(sortOrderDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrderDirection('asc');
    }
    setSortOrder(orderField);
  };

  const loadCompanies = async (searchUpdated: boolean = false) => {
    console.log('Companies status', companiesStatus, searchValue)
    if (companiesStatus === LoadingState.Loading) {
      return;
    }

    if (searchUpdated && page !==0) {
      setPage(0);
      return;
    }

    const queryParams: any = {
      page: (page + 1)
    };
    if (searchValue) {
      queryParams.search = searchValue;
    }
    const orderingFields: Array<any> = [];
    if (sortOrder) {
      orderingFields.push({
        name: sortOrder,
        order: sortOrderDirection
      });
    }
    queryParams.ordering = buildOrdering(orderingFields);
    console.log('Query params', queryParams, searchValue);
    dispatch(fetchCompanies(queryParams));
  };

  const toggleStatus = async (company: Company) => {
    dispatch(updateCompanyStatus({ id: company.id as string, company: { is_active: !company.is_active as boolean } as Company }));
  };

  useEffect(() => {
    loadCompanies();
  }, [page, sortOrder, sortOrderDirection]);

  return (
    <Container maxWidth={false} sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom sx={{ flexGrow: 1 }}>
        Company Listing Page
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <hr />
          <Typography variant="h6" gutterBottom sx={{ flexGrow: 1 }}>
            Filter By
          </Typography>
          <Box sx={{ py: 3 }}>
            <Typography variant="caption">Search</Typography>
            <TextField
              variant="filled"
              placeholder="Name or Code"
              onChange={handleSearchChange}
              value={searchValue}
              className="Search-field"
              sx={{ width: '100%' }}
              onKeyUp={handleSearchKeyUp}
              InputProps={{
                autoComplete: 'off',
                startAdornment:
                  <InputAdornment position="start">
                    <Menu />
                  </InputAdornment>,
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton onClick={() => { loadCompanies(true) }} sx={{ maxBlockSize: 20 }}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
              }}
            />
          </Box>
        </Grid>
        <Grid item md={9}>
          <hr />
          <Box sx={{ display: 'flex', mb: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ flexGrow: 1 }}>
              {companiesCount} Companies
            </Typography>
            <Can I="add_company" a="company_management">
              <Button
                variant="contained"
                component={Link}
                to="/companies/new"
                sx={{ maxBlockSize: 50 }}
              >
                <AddIcon />
                &nbsp;Add Company
              </Button>
            </Can>
          </Box>
          <TableContainer component={Paper} elevation={0}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <SudxTableSortHeaderCell
                    cellKey="name"
                    sortOrder={sortOrder}
                    sortDirection={sortOrderDirection}
                    onSortClick={handleSortChange}
                  >
                    Company Name
                  </SudxTableSortHeaderCell>
                  <SudxTableSortHeaderCell
                    cellKey="code"
                    sortOrder={sortOrder}
                    sortDirection={sortOrderDirection}
                    onSortClick={handleSortChange}
                  >
                    Code
                  </SudxTableSortHeaderCell>
                  <SudxTableSortHeaderCell
                    cellKey="city"
                    sortOrder={sortOrder}
                    sortDirection={sortOrderDirection}
                    onSortClick={handleSortChange}
                  >
                    City
                  </SudxTableSortHeaderCell>
                  <SudxTableSortHeaderCell
                    cellKey="state"
                    sortOrder={sortOrder}
                    sortDirection={sortOrderDirection}
                    onSortClick={handleSortChange}
                  >
                    State
                  </SudxTableSortHeaderCell>
                  <SudxTableSortHeaderCell
                    cellKey="user_count"
                    sortOrder={sortOrder}
                    sortDirection={sortOrderDirection}
                    onSortClick={handleSortChange}
                    sx={{ textAlign: 'center' }}
                  >
                    # of Users
                  </SudxTableSortHeaderCell>
                  <Can I="edit_company_status" a="company_management">
                    <SudxTableSortHeaderCell
                      cellKey="is_active"
                      sortOrder={sortOrder}
                      sortDirection={sortOrderDirection}
                      onSortClick={handleSortChange}
                      sx={{ textAlign: 'center' }}
                    >
                      Status
                    </SudxTableSortHeaderCell>
                  </Can>
                  <Can I="view_company" a="company_management">
                    <TableCell
                      sx={{ textAlign: 'center' }}
                    ></TableCell>
                  </Can>
                </TableRow>
              </TableHead>
              <TableBody>
                {companies.map((company) => (
                  <TableRow
                    key={company.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{company.name}</TableCell>
                    <TableCell>{company.code}</TableCell>
                    <TableCell>{company.city}</TableCell>
                    <TableCell>{company.state}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{company.user_count}</TableCell>
                    <Can I="edit_company_status" a="company_management">
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Button color="secondary" variant="outlined" onClick={() => toggleStatus(company)}>
                          {company.is_active ? 'Active' : 'Inactive'}
                        </Button>
                      </TableCell>
                    </Can>
                    <Can I="view_company" a="company_management">
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Button variant="outlined" component={Link} to={"/companies/" + company.id + "/edit"}>
                          View
                        </Button>
                      </TableCell>
                    </Can>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={companiesCount}
                    rowsPerPage={PAGINATION_PER_PAGE}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPageOptions={[]}
                  ></TablePagination>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

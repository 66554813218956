import { useEffect, useState, Fragment, SyntheticEvent } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { companiesAutoComplete } from '../../services/company.service';
import { FieldError } from 'react-hook-form';
import CompanyAutoComplete from '../../models/CompanyAutoComplete';

interface SudxCompaniesAutoCompleteProps {
  handleChange: Function;
  disabled?: boolean;
  error?: FieldError;
  value?: CompanyAutoComplete;
}

export const SudxCompaniesAutoComplete: React.FC<SudxCompaniesAutoCompleteProps> = (props: SudxCompaniesAutoCompleteProps) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly CompanyAutoComplete[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCompanies('');
  }, [])

  const fetchCompanies = async (inputValue: string) => {
    setLoading(true);
    const companies: Array<CompanyAutoComplete> = (await companiesAutoComplete({ search: inputValue })).data as Array<CompanyAutoComplete>;
    setOptions(companies);
    setLoading(false);
  }

  const handleChange = (event: SyntheticEvent, value: CompanyAutoComplete | null) => {
    if (props.handleChange) {
      props.handleChange({ target: { value } });
    }
  }

  return (
    <Autocomplete
      id="company-autocomplete"
      filterOptions={(x) => x}
      open={open}
      noOptionsText="Type to search"
      disabled={props.disabled || false}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loading}
      value={props.value}
      onInputChange={(event, newInputValue) => {
        fetchCompanies(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Company"
          error={!!props.error}
          helperText={props.error?.message as string}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}

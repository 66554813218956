import { Grid, Box } from "@mui/material"
import { GeneralChartSection } from "./GeneralChartSection"
import { useSelector } from "react-redux";
import { colorAxisSubstanceDetailHeatmapSelector, fetchedHeatmapCompoundsSelector, xAxisBupHistoryLabelsSelector, xAxisNorBupConcLabelsSelector, yAxisSubstanceUseHeatmapLabelsSelector, yDataBupHistoryResultsSelector, yDataBupResultsSelector, yDataNorBupConcQuantAllDoseSelector, yDataNorBupHistoryResultsSelector, yDataNorBupResultsSelector, yDataSubstanceDetailHeatmapDataSelector, yDataSubstanceDetailHeatmapSelector, yDataSubstanceUseHeatmapSelector, yDataSubstanceUseHeatmapStateSelector } from "./dashboardSlice";
import { TooltipFormatterContextObject } from "highcharts";

export const DashboardSet3: React.FC = () => {
  const yDataBupResults = useSelector(yDataBupResultsSelector);
  const yDataNorBupResults = useSelector(yDataNorBupResultsSelector);
  const xAxisBupHistoryLabels = useSelector(xAxisBupHistoryLabelsSelector);
  const yDataBupHistoryResults = useSelector(yDataBupHistoryResultsSelector);
  const yDataNorBupHistoryResults = useSelector(yDataNorBupHistoryResultsSelector);
  const xAxisNorBupConcLabels = useSelector(xAxisNorBupConcLabelsSelector);
  const yDataNorBupConcQuantAllDose = useSelector(yDataNorBupConcQuantAllDoseSelector);
  const yAxisSubstanceUseHeatmapLabels = useSelector(yAxisSubstanceUseHeatmapLabelsSelector);
  const yDataSubstanceUseHeatmap = useSelector(yDataSubstanceUseHeatmapSelector);
  const yDataSubstanceUseHeatmapState = useSelector(yDataSubstanceUseHeatmapStateSelector);
  const yDataSubstanceDetailHeatmap = useSelector(yDataSubstanceDetailHeatmapSelector);
  const yDataSubstanceDetailHeatmapData = useSelector(yDataSubstanceDetailHeatmapDataSelector);
  const colorAxisSubstanceDetailHeatmap = useSelector(colorAxisSubstanceDetailHeatmapSelector);
  const heatmapCompounds = useSelector(fetchedHeatmapCompoundsSelector);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box>
          <GeneralChartSection
            title="Buprenorphine History"
            helpText="This panel displays the positive or negative results (also called qualitative test results) for Buprenorphine and Norbuprenorphine at each patient's attended appointment. Urine tests that are suspected to be adulterated are colored purple. Hovering over a specific test result provides information on the date of the test, the name of the test, and the quantitative level of the specific test."
            background="rgb(245, 249, 251)"
            chartOptions={{
              xAxisCategories: xAxisBupHistoryLabels,
              type: 'heatmap',
              tooltipFormatter: (context: TooltipFormatterContextObject) => {
                const point = context.point;
                const series = context.series;
                const categoryName = series.chart.xAxis[0].categories[point.x];
                if (!point.value || point.value <= 0) { 
                  return false;
                }
                const pointValue = point.y === 0 ? yDataBupResults[point.x] : yDataNorBupResults[point.x];
                return `<p style="margin-top:0;margin-bottom:0.5em">${point.y === 0 ? 'Buprenorphine' : 'Norbuprenorphine'}: ${(pointValue as any).toFixed(0)} ng/ml</p>
                        <p style="margin:0">Appt Date: ${categoryName}</p>`;
              },
              colorAxisDataClasses: [{
                from: -1000000,
                to: -0.00,
                name: 'NEGATIVE',
                color: 'rgb(238, 108, 77)'
              }, {
                from: 0.0001,
                to: 1000000,
                name: 'POSITIVE',
                color: 'rgb(61, 90, 128)'
              }],
              series: [
                {
                  name: 'Buprenorphine History',
                  xData: xAxisBupHistoryLabels,
                  yData: ['Bup', 'Nor-Bup'],
                  heatData: [
                    yDataBupHistoryResults,
                    yDataNorBupHistoryResults
                  ],
                  coordMode: false,
                  toolTipEnabled: true,
                  visible: true
                }
              ]
            }}
          />
        </Box>
        <Box sx={{ display: 'none !important' }}>
          <GeneralChartSection
            title="Bup Metabolism Score"
            helpText="Bup Metabolism Score."
            chartOptions={{
              xAxisCategories: xAxisNorBupConcLabels,
              type: 'bar',
              yAxisText: 'SCORE',
              tooltipFormatter: (context: TooltipFormatterContextObject) => {
                const point = context.point;
                const series = context.series;
                const categoryName = series.chart.xAxis[0].categories[point.x];
                if (point.high === null) {
                  point.high = 0;
                }
                return `<p style="margin-top:0;margin-bottom:0.5em">${((point.low as number) - ((point.high || 0) as number)) > 0 ? 'HIGH RISK (LOW NORBUP CONCENTRATION)' : 'LOW RISK (HIGH NORBUP CONCENTRATION)'}: ${(point.high as number).toFixed(1)}</p>
                        <p style="margin:0">Appt Date: ${categoryName}</p>`;
              },
              series: [{
                name: 'LOW RISK (HIGH NORBUP CONCENTRATION)',
                color: '#3D5A80',
                xData: xAxisNorBupConcLabels,
                yData: yDataNorBupConcQuantAllDose.map((value, key) => ((4 - value) < 0 ? {
                  x: key,
                  low: 4,
                  high: value,
                  color: '#3D5A80'
                } : {
                  x: key,
                  low: 4,
                  high: value,
                  color: '#EE6C4D'
                })),
                toolTipEnabled: true,
                visible: true
              }, {
                name: 'HIGH RISK (LOW NORBUP CONCENTRATION)',
                color: '#EE6C4D',
                type: 'scatter',
                toolTipEnabled: true,
                visible: true, // Set to true to avoid being struck out
                data: []
              }]
            }}
          />
        </Box>
        <Box>
          <GeneralChartSection
            title="Substance Use Heatmap"
            helpText="This panel displays the qualitative (positive or negative urine tests) urine test results for common substances of abuse (and their metabolites) at each patient's attended appointment. Hovering over a positive test provides information on the date of the test, the name of the test, and the quantitative level of the specific test."
            chartOptions={{
              xAxisCategories: xAxisBupHistoryLabels,
              type: 'heatmap',
              tooltipFormatter: (context: TooltipFormatterContextObject) => {
                const point = context.point;
                if (!point.value || point.value <= 0) { 
                  return false;
                }
                const substanceUseHeatmapLabels = yAxisSubstanceUseHeatmapLabels;
                const heatmapCompoundName: string = substanceUseHeatmapLabels[point.y as number];
                if (!heatmapCompoundName) {
                  return false;
                }
                const series = context.series;
                const categoryName = series.chart.xAxis[0].categories[point.x];
                const labTestNames: Array<string> = heatmapCompounds[heatmapCompoundName].tests;
                const substanceDetailHeatmapData = yDataSubstanceDetailHeatmapData;
                const labTestsHTML: Array<string|undefined> = labTestNames.map(labTestName => {
                  for(const heatmapCell of substanceDetailHeatmapData[point.x]) {
                    if (heatmapCell.name === labTestName) {
                      return `<p style="margin-top:0;margin-bottom:0.5em">${heatmapCell.name}: ${(heatmapCell.value).toFixed(0)} ng/ml</p>`;
                    }
                  }
                }).filter(labTestHTML => labTestHTML);
                if (labTestsHTML.length < 1) {
                  return false;
                }
                return `${labTestsHTML.join('')}
                        <p style="margin:0">Appt Date: ${categoryName}</p>`;
              },
              colorAxisDataClasses: [{
                from: -1000000,
                to: -0.01,
                name: 'NEGATIVE',
                color: 'rgb(61, 90, 128)'
              }, {
                from: -0.001,
                to: 0.001,
                name: 'NOT ORDERED',
                color: 'rgb(152, 193, 217)'
              }, {
                from: 0.01,
                to: 1000000,
                name: 'POSITIVE',
                color: 'rgb(238, 108, 77)'
              }],
              series: [
                {
                  name: 'Substance Use Heatmap',
                  xData: xAxisBupHistoryLabels,
                  yData: yAxisSubstanceUseHeatmapLabels,
                  heatData: yDataSubstanceUseHeatmapState,
                  coordMode: false,
                  toolTipEnabled: true,
                  visible: true
                }
              ]
            }}
          />
        </Box>
        <Box>
          <GeneralChartSection
            title="Substance Use Detail"
            helpText="This panel displays the positive or negative results (also called qualitative test results) for Buprenorphine and Norbuprenorphine at each patient's attended appointment. Urine tests that are suspected to be adulterated are colored purple. Hovering over a specific test result provides information on the date of the test, the name of the test, and the quantitative level of the specific test."
            background="rgb(245, 249, 251)"
            chartOptions={{
              xAxisCategories: xAxisBupHistoryLabels,
              type: 'heatmap',
              tooltipFormatter: (context: TooltipFormatterContextObject) => {
                const point = context.point;
                const series = context.series;
                const categoryName = series.chart.xAxis[0].categories[point.x];
                const substanceDetailHeatmapData = yDataSubstanceDetailHeatmapData;
                if (!substanceDetailHeatmapData[point.x][point.y as number]) {
                  return false;
                }
                return `<p style="margin-top:0;margin-bottom:0.5em">${substanceDetailHeatmapData[point.x][point.y as number].name}: ${(substanceDetailHeatmapData[point.x][point.y as number].value).toFixed(0)} ng/ml</p>
                        <p style="margin:0">Appt Date: ${categoryName}</p>`;
              },
              colorAxisDataClasses: colorAxisSubstanceDetailHeatmap,
              series: [
                {
                  name: 'Substance Use Detail',
                  xData: xAxisBupHistoryLabels,
                  yData: Array.from(Array(Math.max(...yDataSubstanceDetailHeatmap.map(columnData => columnData.length).concat(0)))).map((_, k) => '').concat(['']),
                  heatData: yDataSubstanceDetailHeatmap,
                  coordMode: true,
                  toolTipEnabled: true,
                  visible: true
                }
              ]
            }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
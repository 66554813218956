import React from "react";
import { PageLayout } from "../components/PageLayout";
import { CompanyForm } from "../features/companies/CompanyForm";

export const CreateCompanyPage: React.FC = () => {
  return (
    <PageLayout>
      <CompanyForm></CompanyForm>
    </PageLayout>
  );
};

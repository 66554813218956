import { ThemeOptions, createTheme } from '@mui/material/styles';

const sudxThemeOptions: ThemeOptions = {
  palette: {
      mode: 'light',
      primary: {
          main: '#000000'
      },
      secondary: {
        main: '#6750A4'
      },
      info: {
        main: '#98C1D9'
      }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          background: 'linear-gradient(90deg, rgba(152, 193, 217, 0) 9.84%, #98C1D9 100%)',
          boxShadow: 'none',
          color: '#000000'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          textTransform: 'capitalize'
        }
      }
    }
  },
  typography: {

  }
};

export const sudxTheme = createTheme(sudxThemeOptions);

import { Box, Button, Container, Grid, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchUser, setSaveUserStatus, formUserSelector, saveUser, savingUserStatusSelector, setFormNewUser, userProfileSelector, fetchingUserProfileSelector, fetchingUserSelector, rolesSelector, fetchingRolesSelector, fetchRoles, setSnackBar } from "./usersSlice";
import { useAppDispatch } from "../../app/store";
import { LoadingState } from "../../models/enums";
import { useSelector } from "react-redux";
import User from "../../models/User";
import { SudxBackButton } from '../../components/common/SudxBackButton';
import { SudxCompaniesAutoComplete } from "../../components/common/SudxCompaniesAutoComplete";
import { Controller, FieldValues, useForm, useWatch } from "react-hook-form";
import { PageLoader } from "../../components/PageLoader";
import Role from "../../models/Role";
import { Can } from "../../Can";
import { fetchPatientsAndCompounds } from "../dashboard/dashboardSlice";

const simpleFormFields = [
  'first_name',
  'last_name',
  'email',
  'title',
]

export const UserForm: React.FC<{ profile?: boolean }> = (props: { profile?: boolean }) => {
  const dispatch = useAppDispatch();
  const savingUserStatus: LoadingState = useSelector(savingUserStatusSelector);
  const formUser: User = useSelector(formUserSelector);
  const userProfile: User = useSelector(userProfileSelector);
  const fetchingUserProfile: LoadingState = useSelector(fetchingUserProfileSelector);
  const fetchingUser: LoadingState = useSelector(fetchingUserSelector);
  const fetchingRoles: LoadingState = useSelector(fetchingRolesSelector);
  const roles: Array<Role> = useSelector(rolesSelector);
  const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [savingUser, setSavingUser] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [emailEditable, setEmailEditable] = useState<boolean>(!userId || !props.profile);

  useWatch({
    control,
    name: 'roleId',
    defaultValue: ''
  });

  useEffect(() => {
    if (fetchingRoles === LoadingState.Idle) {
      dispatch(fetchRoles());
    }
  }, []);
  
  useEffect(() => {
    setLoadingForm(
      (fetchingRoles === LoadingState.Loading || (!!props.profile && fetchingUserProfile === LoadingState.Loading) || (!props.profile && !!userId && fetchingUser === LoadingState.Loading))
    );
  }, [fetchingRoles, userId, fetchingUser, fetchingUserProfile]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUser(userId));
      setEmailEditable(false);
    } else {
      dispatch(setFormNewUser());
      setEmailEditable(!props.profile);
    }
  }, [userId]);

  useEffect(() => {
    if (!props.profile) {
      setFormUser(formUser);
    }
  }, [formUser]);

  useEffect(() => {
    if (props.profile) {
      setFormUser(userProfile);
    }
  }, [userProfile]);

  useEffect(() => {
    if (savingUserStatus === LoadingState.Loading) {
      setSavingUser(true);
    } else {
      setSavingUser(false);
    }

    if (savingUserStatus === LoadingState.Succeeded) {
      dispatch(setSaveUserStatus({ savingUserStatus: LoadingState.Idle }));
      dispatch(setSnackBar({ snackBarType: 'success', snackBarText: (props.profile ? 'Profile updated' : (userId ? 'User updated' : 'User created')) }));
      dispatch(fetchPatientsAndCompounds({}));
      navigate('/users');
    }
  }, [savingUserStatus]);

  const setFormUser = (user: User) => {
    for (const formFieldName of simpleFormFields) {
      setValue(formFieldName, user[formFieldName]);
    }
    const company = user.company ? {
      id: user.company.id,
      label: `${user.company.name} - ${user.company.code}`
    } : null;
    setValue('company', company)
    setValue('roleId', (user.roles || [])[0]?.id);
  }

  const dispatchSaveUser = (data: User) => {
    data.company_id = data.company?.id as string;
    if (!props.profile) {
      data.roles = [{id: data.roleId}];
    }
    dispatch(saveUser({ id: (props.profile ? userProfile.id : userId), user: data }));
  }

  const onSubmit = (data: FieldValues) => {
    dispatchSaveUser(data as User);
  }

  return (
    loadingForm ?
      <PageLoader /> : 
      <Container sx={{ py: 5, maxWidth: 900, width: 900 }}>
        <Typography variant="h4" gutterBottom sx={{ flexGrow: 1, mb: 3 }}>
          {props.profile ? 'My Profile' : userId ? 'Edit User' : 'New User' }
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="First Name"
                  disabled={savingUser}
                  sx={{ width: '100%' }}
                  error={!!errors.first_name}
                  helperText={errors.first_name?.message as string}
                  {...register("first_name", { required: "First Name is required" })}
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Last Name"
                  disabled={savingUser}
                  sx={{ width: '100%' }}
                  error={!!errors.last_name}
                  helperText={errors.last_name?.message as string}
                  {...register("last_name", { required: "Last Name is required" })}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Email"
                  disabled={savingUser || !emailEditable}
                  sx={{ width: '100%' }}
                  error={!!errors.email}
                  helperText={errors.email?.message as string}
                  {...register("email", { 
                    required: "Email is required", 
                    pattern: { 
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 
                      message: "Invalid email address" 
                    }
                  })}
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Title"
                  disabled={savingUser}
                  sx={{ width: '100%' }}
                  {...register("title")}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {!props.profile && 
              <Can I="assign_to_company" a="user_management">
                <Grid item md={6}>
                  <Box sx={{ mb: 2 }}>
                    <Controller
                        name="company"
                        control={control}
                        rules={{ required: 'Company is required' }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <SudxCompaniesAutoComplete 
                            error={error} 
                            value={value || null} 
                            disabled={savingUser}
                            handleChange={onChange}
                          />
                        )
                      }
                    />
                  </Box>
                </Grid>
              </Can>
            }
            {!props.profile && 
              <Can I="assign_other_role" a="user_management">
                <Grid item md={6}>
                  <Box sx={{ mb: 2 }}>
                    <Controller
                      name="roleId"
                      control={control}
                      rules={{ required: 'Role is required' }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                          <TextField
                            label="Role"
                            select
                            disabled={savingUser}
                            sx={{ width: '100%' }}
                            value={value || ''}
                            error={!!error}
                            helperText={error?.message as string}
                            onChange={onChange}
                          >
                            {roles.map(role => (
                              <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                            ))}
                          </TextField>
                        )
                      }
                    />
                  </Box>
                </Grid>
              </Can>
            }
          </Grid>
          <Box sx={{ pb: 2, pt: 5, display: 'flex' }}>
            <Button
              disabled={savingUser}
              variant="contained"
              type="submit"
              sx={{blockSize: 52, px: 4, minWidth: 230}}
            >
              Save Changes
            </Button>
            <Box sx={{ px: 1 }} />
            <SudxBackButton
              sx={{blockSize: 52, px: 4, minWidth: 230}}
            >
              Cancel
            </SudxBackButton>
            <Box sx={{ flexGrow: 1 }} />
            {props.profile && 
              <Button
                disabled={savingUser}
                variant="outlined"
                component={Link}
                to="/change_password"
                sx={{blockSize: 52, px: 4, minWidth: 230}}
              >
                Change Password
              </Button>
            }
          </Box>
        </form>
      </Container>
  );
};

import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { processUserInvitation } from "../services/user.service";

export const LoginPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();

  const initiateLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  }
  
  useEffect(() => {
    (async () => {
      const invitationCode = searchParams.get('invitation');
      if (!invitationCode) {
        initiateLogin();
      } else {
        try {
          console.log('Invitation code present');
          const result = await processUserInvitation({ invitation_code: invitationCode });
          const passwordChangeTicketUrl: string = (result.data as any).ticket;
          if (passwordChangeTicketUrl) {
            window.location.href = passwordChangeTicketUrl;
          } else {
            initiateLogin();
          }
        } catch (err) {
          console.error('Error processing invitation', err);
          initiateLogin();
        }
      }
    })();
  }, []);
  
  return (
    <></>
  );
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPatients, postPatientResultsViewed } from '../../services/patient.service';
import { getCompounds, loadStabilityScores, loadLabOrderedTestResults, loadNorbupConcRiskScores } from '../../services/chart-data.service';
import { LoadingState } from '../../models/enums';
import RootState from '../../models/RootState';
import PatientAutoComplete from '../../models/PatientAutoComplete';
import DashboardState from '../../models/DashboardState';
import { SUBSTANCE_USE_HEAT_MAP_LABELS } from '../../utils/constants';
import { normaliseChartData } from './chart-data-normalisation';

export const fetchPatientsAndCompounds = createAsyncThunk('dashboard/fetchPatientsAndCompounds', async (queryParams: any) => {
  return {
    patientsData: (await getPatients(queryParams)).data,
    compoundsData: (await getCompounds(queryParams)).data
  }
});

export const fetchPatientData = createAsyncThunk('dashboard/fetchPatientData', async (payload: { patient_name_dob: string, location: string }) => {
  const result = {
    stabilityScoresData: (await loadStabilityScores(payload)).data,
    labOrderedTestResultsData: (await loadLabOrderedTestResults(payload)).data,
    norbupConcRiskScoresData: (await loadNorbupConcRiskScores(payload)).data
  }
  postPatientResultsViewed(payload);
  return result;
});

const initialState: DashboardState = {
  patients: [],
  patientsCount: 0,
  status: LoadingState.Idle,
  fetchingPatientData: LoadingState.Idle,
  compounds: [] as Array<any>,
  stabilityScores: [],
  labOrderedTestResults: [],
  norbupConcRiskScores: [],
  heatmapCompounds: {
    Alcohol: {index: 9, tests: []},
    Amphetamine: {index: 8, tests: []},
    Benzodiazepine: {index: 7, tests: []},
    Cocaine: {index: 6, tests: []},
    Fentanyl: {index: 5, tests: []},
    Gabapentin: {index: 4, tests: []},
    Heroin: {index: 3, tests: []},
    Methadone: {index: 2, tests: []},
    'Other Opioids': {index: 1, tests: []},
    'Other Drugs': {index: 0, tests: []}
  } as any,
  heatmapReverse: {} as any,
  xAxisStabilityScoresLabels: [],
  xAxisStabilityScoresTime: [],
  yDataOudStabilityScores: [],
  yDataNonOpioidDrugUseScores: [],
  xAxisBupConcLabels: [],
  xAxisBupConcTime: [],
  yDataBupResults: [],
  yDataNorBupResults: [],
  yDataNalaxoneResults: [],
  yDataNorBupRatioResults: [],
  yAxisSubstanceUseHeatmapLabels: SUBSTANCE_USE_HEAT_MAP_LABELS,
  xAxisBupHistoryLabels: [],
  xAxisNorBupConcLabels: [],
  yDataBupHistoryResults: [],
  yDataNorBupHistoryResults: [],
  yDataSubstanceUseHeatmap: Array.from(Array(SUBSTANCE_USE_HEAT_MAP_LABELS.length)).map(_ => []),
  yDataSubstanceUseHeatmapState: Array.from(Array(SUBSTANCE_USE_HEAT_MAP_LABELS.length)).map(_ => []),
  yDataSubstanceDetailHeatmap: Array.from(Array(SUBSTANCE_USE_HEAT_MAP_LABELS.length)).map(_ => []),
  yDataSubstanceDetailHeatmapData: Array.from(Array(SUBSTANCE_USE_HEAT_MAP_LABELS.length)).map(_ => []),
  colorAxisSubstanceDetailHeatmap: [],
  yDataNorBupConcQuantAllDose: [],
  dateFormat: 'appointment_interval',
  dateRange: 'all_time'
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardDateFormat: (state: DashboardState, payload) => {
      state.dateFormat = payload.payload.dateFormat;
    },
    setDashboardDateRange: (state: DashboardState, payload) => {
      state.dateRange = payload.payload.dateRange;
      normaliseChartData(state);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPatientsAndCompounds.pending, (state, action) => {
        state.status = LoadingState.Loading;
      })
      .addCase(fetchPatientsAndCompounds.fulfilled, (state, action): any => {
        const result: any = action.payload;
        state.patients = (result.patientsData || []).sort((a: PatientAutoComplete, b: PatientAutoComplete) => a.name_dob.localeCompare(b.name_dob));
        state.patientsCount = state.patients.length;
        state.compounds = result.compoundsData;
        if (state.compounds && state.compounds.length) {
          for (const compound of state.compounds) {
            if (state.heatmapCompounds[compound.heat_map] && state.heatmapCompounds[compound.heat_map].tests.indexOf(compound.dashboard_name) < 0) {
              state.heatmapCompounds[compound.heat_map].tests.push(compound.dashboard_name);
              state.heatmapReverse[compound.dashboard_name] = compound.heat_map;
            }
          }
        }
        state.status = LoadingState.Succeeded;
      })
      .addCase(fetchPatientData.pending, (state, action) => {
        state.fetchingPatientData = LoadingState.Loading;
      })
      .addCase(fetchPatientData.fulfilled, (state, action): any => {
        const result: any = action.payload;
        state.stabilityScores = result.stabilityScoresData || [];
        state.labOrderedTestResults = result.labOrderedTestResultsData || [];
        state.norbupConcRiskScores = result.norbupConcRiskScoresData || [];
        normaliseChartData(state);
        state.fetchingPatientData = LoadingState.Succeeded;
      });
  },
});

export const fetchedPatientsSelector = (state: RootState): Array<any> => state.dashboard.patients;
export const fetchedCompoundsSelector = (state: RootState): Array<any> => state.dashboard.compounds;
export const fetchedHeatmapCompoundsSelector = (state: RootState): any => state.dashboard.heatmapCompounds;
export const fetchedHeatmapReverseSelector = (state: RootState): Array<any> => state.dashboard.heatmapReverse;
export const fetchedStabilityScoresSelector = (state: RootState): Array<any> => state.dashboard.stabilityScores;
export const fetchedLabOrderedTestResultsSelector = (state: RootState): Array<any> => state.dashboard.labOrderedTestResults;
export const fetchingPatientDataSelector = (state: RootState): LoadingState => state.dashboard.fetchingPatientData;
export const dashboardStatusSelector = (state: RootState): LoadingState => state.dashboard.status;
export const patientsCountSelector = (state: RootState): number => state.dashboard.patientsCount;
export const xAxisStabilityScoresLabelsSelector = (state: RootState): Array<string> => state.dashboard.xAxisStabilityScoresLabels;
export const xAxisStabilityScoresTimeSelector = (state: RootState): Array<number> => state.dashboard.xAxisStabilityScoresTime;
export const yDataOudStabilityScoresSelector = (state: RootState): Array<number> => state.dashboard.yDataOudStabilityScores;
export const yDataNonOpioidDrugUseScoresSelector = (state: RootState): Array<number> => state.dashboard.yDataNonOpioidDrugUseScores;
export const xAxisBupConcLabelsSelector = (state: RootState): Array<string> => state.dashboard.xAxisBupConcLabels;
export const xAxisBupConcTimeSelector = (state: RootState): Array<number> => state.dashboard.xAxisBupConcTime;
export const yDataBupResultsSelector = (state: RootState): Array<number> => state.dashboard.yDataBupResults;
export const yDataNorBupResultsSelector = (state: RootState): Array<number> => state.dashboard.yDataNorBupResults;
export const yDataNalaxoneResultsSelector = (state: RootState): Array<number> => state.dashboard.yDataNalaxoneResults;
export const yDataNorBupRatioResultsSelector = (state: RootState): Array<number> => state.dashboard.yDataNorBupRatioResults;
export const yAxisSubstanceUseHeatmapLabelsSelector = (state: RootState): Array<string> => state.dashboard.yAxisSubstanceUseHeatmapLabels;
export const xAxisBupHistoryLabelsSelector = (state: RootState): Array<string> => state.dashboard.xAxisBupHistoryLabels;
export const yDataBupHistoryResultsSelector = (state: RootState): Array<number> => state.dashboard.yDataBupHistoryResults;
export const yDataNorBupHistoryResultsSelector = (state: RootState): Array<number> => state.dashboard.yDataNorBupHistoryResults;
export const yDataSubstanceUseHeatmapSelector = (state: RootState): Array<Array<any>> => state.dashboard.yDataSubstanceUseHeatmap;
export const yDataSubstanceUseHeatmapStateSelector = (state: RootState): Array<Array<any>> => state.dashboard.yDataSubstanceUseHeatmapState;
export const yDataSubstanceDetailHeatmapSelector = (state: RootState): Array<Array<any>> => state.dashboard.yDataSubstanceDetailHeatmap;
export const yDataSubstanceDetailHeatmapDataSelector = (state: RootState): Array<Array<any>> => state.dashboard.yDataSubstanceDetailHeatmapData;
export const colorAxisSubstanceDetailHeatmapSelector = (state: RootState): Array<any> => state.dashboard.colorAxisSubstanceDetailHeatmap;
export const yDataNorBupConcQuantAllDoseSelector = (state: RootState): Array<number> => state.dashboard.yDataNorBupConcQuantAllDose;
export const xAxisNorBupConcLabelsSelector = (state: RootState): Array<string> => state.dashboard.xAxisNorBupConcLabels;
export const dateFormatSelector = (state: RootState): 'appointment_interval'|'calendar' => state.dashboard.dateFormat;
export const dateRangeSelector = (state: RootState): 'last_6_months'|'last_1_year'|'all_time' => state.dashboard.dateRange;

export const { setDashboardDateFormat, setDashboardDateRange } = dashboardSlice.actions;

export default dashboardSlice.reducer;

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AverageCurrentScore } from "./AverageCurrentScore";
import { yDataNonOpioidDrugUseScoresSelector } from "./dashboardSlice";

export const NonOpioidUseScore: React.FC = () => {
  const yDataNonOpioidDrugUseScores: Array<any> = useSelector(yDataNonOpioidDrugUseScoresSelector);
  const [averageValue, setAverageValue] = useState<number>(0);
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [changeValue, setChangeValue] = useState<number>(0);

  useEffect(() => {
    if (yDataNonOpioidDrugUseScores && yDataNonOpioidDrugUseScores.length) {
      setAverageValue(Math.round(yDataNonOpioidDrugUseScores.reduce((total: number, score: any) => (total + score), 0) / yDataNonOpioidDrugUseScores.length));
      setCurrentValue(Math.round((yDataNonOpioidDrugUseScores[yDataNonOpioidDrugUseScores.length - 1] || 0)));
      if (yDataNonOpioidDrugUseScores.length > 1) {
        setChangeValue(Math.round((yDataNonOpioidDrugUseScores[yDataNonOpioidDrugUseScores.length - 1] || 0)) - Math.round((yDataNonOpioidDrugUseScores[yDataNonOpioidDrugUseScores.length - 2] || 0)));
      } else {
        setChangeValue(0);
      }
    } else {
      setAverageValue(0);
      setCurrentValue(0);
      setChangeValue(0);
    }
  }, [yDataNonOpioidDrugUseScores]);
  
  return (
    <AverageCurrentScore averageValue={averageValue} currentValue={currentValue} changeValue={changeValue} title="Non-Opioid Use Score" helpText="The average value is based on full patient's history." />
  )
}
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { TooltipFormatterContextObject } from 'highcharts'; 
import AccessibilityModule from 'highcharts/modules/accessibility';
import { highChartsMouseOverMethod, highChartsMouseOutMethod } from './misc';

AccessibilityModule(Highcharts);

export interface LineChartProps {
  xAxisCategories: Array<string|number>;
  yAxisText: string;
  yAxisTickPositions: Array<number>;
  type: string;
  tooltipFormatter?: Function;
  xAxisType?: string|null;
  legendY?: number,
  series: Array<{
    name: string,
    type: string,
    color: string,
    xData: Array<string|number>,
    yData: Array<number|null>,
    toolTipEnabled: boolean,
    linkedTo?: string,
    visible: boolean
  }>;
}

export const LineChart: React.FC<LineChartProps> = (props: LineChartProps) => {
  const defaultTooltipFormatter: Function = (context: TooltipFormatterContextObject) => {
    const point = context.point;
    const series = context.series;
    const categoryName = series.chart.xAxis[0].categories[point.x];
    return `<p style="margin-top:0;margin-bottom:0.5em">${series.name}: ${(point.y as any).toFixed(0)}</p>
            <p style="margin:0">Appt Date: ${categoryName}</p>`;
  }

  const oneDayInterval = 24 * 3600 * 1000;

  let dateTimeTickInterval = oneDayInterval;
  let dateTimeFormat = '{value:%e. %b}';
  
  if (props.xAxisType === 'datetime') {
    const daysCount: number = Math.ceil((Math.max(...props.xAxisCategories as number[]) - Math.min(...props.xAxisCategories as number[])) / oneDayInterval);
    if (daysCount > 240) {
      dateTimeTickInterval *= 90;
      dateTimeFormat = "{value:%b '%y}";
    } else if (daysCount > 60) {
      dateTimeTickInterval *= 30;
      dateTimeFormat = "{value:%b '%y}";
    } else if (daysCount > 10) {
      dateTimeTickInterval *= 7;
    }
  }

  const options = {
    chart: {
      type: 'line',
      animation: false,
      marginLeft: 134,
      style: {
        fontFamily: "SF Pro Text"
      },
      scrollablePlotArea: {
        minWidth: '1200px',
        scrollPositionX: 1
      },
      backgroundColor: null,
      reflow: true
    },
    credits: false,
    title: {
      text: ''
    },
    xAxis: (props.xAxisType === 'datetime' ? {
      lineColor: "#EAEBEC",
      type: 'datetime',
      tickInterval: dateTimeTickInterval,
      labels: {
        format: dateTimeFormat,
        style: {
          color: "#7B828B",
          fontFamily: "SF Pro Text",
          fontSize: "12px",
          textTransform: "uppercase"
        }
      }
    } : {
      categories: props.xAxisCategories,
      lineColor: "#EAEBEC",
      labels: {
        style: {
          color: "#7B828B",
          fontFamily: "SF Pro Text",
          fontSize: "12px",
          textTransform: "uppercase"
        }
      }
    }),
    yAxis: {
      title: {
        text: props.yAxisText,
        style: {
          color: "#7B828B",
          fontFamily: "SF Pro Text",
          fontSize: "12px",
          textTransform: "uppercase"
        }
      },
      labels: {
        x: -64,
        align: 'right',
        style: {
          color: "#7B828B",
          fontFamily: "SF Pro Text",
          fontSize: "12px",
          textTransform: "uppercase"
        },
        formatter: function(): any {
          return ((context: any) => (context.value && context.value >= 1000 ? `${Math.round(context.value / 1000)}k` : `${context.value}`))(this);
        }
      },
      gridLineColor: "#EAEBEC",
      max: 100,
      tickPositions: props.yAxisTickPositions
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      floating: true,
      itemMarginTop: 3,
      itemMarginBottom: 3,
      y: (props.legendY || -75),
      itemStyle: {
        color: "#293241",
        fontFamily: "SF Pro Text",
        fontSize: "12px",
        fontWeight: "400",
        textTransform: "uppercase"
      },
      enabled: true
    },
    tooltip: {
      animation: false,
      backgroundColor: "#FFF",
      borderColor: "#EAEBEC",
      borderRadius: 0,
      borderWidth: 1,
      style: {
        color: "#293241",
        fontFamily: "SF Pro Text",
        fontSize: "12px",
        fontWeight: "400"
      },
      followPointer: false,
      useHTML: true,
      formatter: function() {
        return (props.tooltipFormatter || defaultTooltipFormatter)(this);
      },
      headerFormat: "",
      hideDelay: 10000,
      shared: false,
      crosshairs: true
    },
    plotOptions: {
      series: {
        animation: false,
        point: {
          /*events: {
            mouseOver: mouseOverEvent,
            mouseOut: mouseOutEvent
          }*/
        }
      },
      line: {
        lineWidth: 2,
        marker: {
          symbol: "circle",
          radius: 4
        }
      }
    },
    series: props.series.map(seriesData => {
      return {
        data: seriesData.xData.map((value, key) => [props.xAxisType === 'datetime' ? Number(value) : props.xAxisCategories.indexOf(value), seriesData.yData[key]]),
        type: seriesData.type,
        name: seriesData.name,
        color: seriesData.color,
        tooltip: { enabled: seriesData.toolTipEnabled },
        visible: seriesData.visible,
        enableMouseTracking: true,
        linkedTo: seriesData.linkedTo,
        showInLegend: true,
        point: {
          events: {
            mouseOver: function (event: any) {
              highChartsMouseOverMethod(this, event);
            },
            mouseOut: function (event: any) {
              highChartsMouseOutMethod(this, event);
            }
          }
        }
      }
    })
  }
  return (
    <HighchartsReact sx={{ width: '100%', overflowY: 'visible !important', overflow: 'visible !important' }}
      highcharts={Highcharts}
      options={options}
      containerProps={{ style: { width: '100%', height: '100%', overflowY: 'visible !important', overflow: 'visible !important' } }}
    />
  )
}
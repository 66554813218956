import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanies, createCompany, updateCompany, getCompany, partialUpdateCompany, getCompanyProfile } from '../../services/company.service';
import CompaniesState from '../../models/CompaniesState';
import { LoadingState } from '../../models/enums';
import CompaniesQueryParams from '../../models/CompaniesQueryParams';
import Company from '../../models/Company';
import RootState from '../../models/RootState';

const initialState: CompaniesState = {
  savingCompanyStatus: LoadingState.Idle,
  fetchingCompanyStatus: LoadingState.Idle,
  fetchingCompanyProfile: LoadingState.Idle,
  status: LoadingState.Idle,
  companiesCount: 0,
  filteredCompanies: [],
  formCompany: {} as Company,
  companyProfile: {} as Company,
};

export const fetchCompanies = createAsyncThunk('companies/fetchCompanies', async (queryParams: CompaniesQueryParams) => {
  const response = await getCompanies(queryParams);
  return response.data;
});

export const fetchCompany = createAsyncThunk('companies/fetchCompany', async (companyId: string): Promise<Company> => {
  const response = await getCompany(companyId);
  return response.data as Company;
});

export const fetchCompanyProfile = createAsyncThunk('users/fetchCompanyProfile', async () => {
  const response = await getCompanyProfile();
  return response.data;
});

export const saveCompany = createAsyncThunk('companies/saveCompany', async (payload: { id?: string, company: Company }) => {
  const companyId: string | undefined = payload.id;
  try {
    const response = await (companyId ? updateCompany(companyId, payload.company) : createCompany(payload.company));
    if (response.error) {
      return;
    } else {
      return response;
    }
  } catch (err) {
    console.error('Error saving company', err);
  }
});

export const updateCompanyStatus = createAsyncThunk('companies/updateStatus', async (payload: { id: string, company: Company }) => {
  try {
    const response = await partialUpdateCompany(payload.id, payload.company);
    if (response.error) {
      return;
    } else {
      return response.data;
    }
  } catch (err) {
    console.error('Error saving company', err);
  }
});

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setFormNewCompany: (state: CompaniesState) => {
      state.formCompany = {} as Company;
    },
    setSaveCompanyStatus: (state: CompaniesState, payload) => {
      console.log('Saving company status being set', payload, payload.payload);
      state.savingCompanyStatus = payload.payload.savingCompanyStatus;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompanies.pending, (state, action) => {
        state.status = LoadingState.Loading;
      })
      .addCase(fetchCompanies.fulfilled, (state, action): any => {
        const result: any = action.payload;
        state.companiesCount = result.count;
        state.filteredCompanies = result.results;
        state.status = LoadingState.Succeeded;
      })
      .addCase(fetchCompany.pending, (state, action) => {
        state.fetchingCompanyStatus = LoadingState.Loading;
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.formCompany = action.payload;
        state.fetchingCompanyStatus = LoadingState.Succeeded;
      })
      .addCase(fetchCompanyProfile.pending, (state, action) => {
        state.fetchingCompanyProfile = LoadingState.Loading;
      })
      .addCase(fetchCompanyProfile.fulfilled, (state, action) => {
        state.fetchingCompanyProfile = LoadingState.Succeeded;
        state.companyProfile = (action.payload || {}) as Company;
      })
      .addCase(updateCompanyStatus.fulfilled, (state, action) => {
        if (action.payload ) {
          const company: Company = action.payload as Company;
          const companyId = company.id;
          const filteredCompanies = state.filteredCompanies;
          const filteredCompany = filteredCompanies.filter(filteredCompany => filteredCompany.id === companyId)[0];
          if (filteredCompany) {
            filteredCompany.is_active = !filteredCompany.is_active;
          }
          state.filteredCompanies = filteredCompanies;
          state.savingCompanyStatus = LoadingState.Succeeded;
        } else {
          state.savingCompanyStatus = LoadingState.Failed;
        }
      })
      .addCase(saveCompany.pending, (state, action) => {
        state.savingCompanyStatus = LoadingState.Loading;
      })
      .addCase(saveCompany.fulfilled, (state, action) => {
        if (action.payload ) {
          state.savingCompanyStatus = LoadingState.Succeeded;
        } else {
          state.savingCompanyStatus = LoadingState.Failed;
        }
      });
  },
});

export const fetchedCompaniesSelector = (state: RootState): Array<Company> => state.companies.filteredCompanies;
export const formCompanySelector = (state: RootState): Company => state.companies.formCompany;
export const companiesStatusSelector = (state: RootState): LoadingState => state.companies.status;
export const companiesCountSelector = (state: RootState): number => state.companies.companiesCount;
export const savingCompanyStatusSelector = (state: RootState): LoadingState => state.companies.savingCompanyStatus;
export const fetchingCompanyStatusSelector = (state: RootState): LoadingState => state.companies.fetchingCompanyStatus;
export const fetchingCompanyProfileSelector = (state: RootState): LoadingState => state.companies.fetchingCompanyProfile;
export const companyProfileSelector = (state: RootState): Company => state.companies.companyProfile;

export const { setFormNewCompany, setSaveCompanyStatus } = companiesSlice.actions;

export default companiesSlice.reducer;

import { Grid, Box, Typography } from "@mui/material";
import { OudStabilityScore } from "./OudStabilityScore";
import { NonOpioidUseScore } from './NonOpioidUseScore';
import { DashboardPatientInfoList } from "./DashboardPatientInfoList";
import { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const DashboardSet1: React.FC = () => {
  const [expanded, setExpanded] = useState(true);
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ width: '63%' }}>
        <Typography variant="h2" gutterBottom sx={{
          fontSize: '20px',
          lineHeight: '20px',
          letterSpacing: '1px',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '18.6px'
        }}>
          {expanded ? 
            <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} onClick={() => {setExpanded(false)}}/> 
            : 
            <KeyboardArrowRightIcon sx={{ cursor: 'pointer' }} onClick={() => {setExpanded(true)}}/> 
          }
          &nbsp;
          Summary
          &nbsp;
        </Typography>
        {expanded && 
          <Grid container spacing={2}>
            <Grid item lg={6}>
              <Box sx={{
                padding: '18.75px',
                marginLeft: '37px'
              }}>
                <OudStabilityScore />
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box sx={{
                padding: '18.75px',
                marginLeft: '37px'
              }}>
                <NonOpioidUseScore />
              </Box>
            </Grid>
          </Grid>
        }
      </Box>
      <Box sx={{ width: '37%', paddingLeft: '16px' }}>
        <Typography variant="h2" gutterBottom sx={{
          fontSize: '20px',
          lineHeight: '20px',
          letterSpacing: '1px',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '18.6px',
          opacity: 0
        }}>
          {expanded ? 
            <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} onClick={() => {setExpanded(false)}}/> 
            : 
            <KeyboardArrowRightIcon sx={{ cursor: 'pointer' }} onClick={() => {setExpanded(true)}}/> 
          }
          &nbsp;
          Summary
          &nbsp;
        </Typography>
        {expanded && <DashboardPatientInfoList />}
      </Box>
    </Box>
  )
}
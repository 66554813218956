import { useSelector } from "react-redux";
import { AverageCurrentScore } from "./AverageCurrentScore";
import { yDataOudStabilityScoresSelector } from "./dashboardSlice";
import { useEffect, useState } from "react";

export const OudStabilityScore: React.FC = () => {
  const yDataOudStabilityScores: Array<any> = useSelector(yDataOudStabilityScoresSelector);
  const [averageValue, setAverageValue] = useState<number>(0);
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [changeValue, setChangeValue] = useState<number>(0);

  useEffect(() => {
    if (yDataOudStabilityScores && yDataOudStabilityScores.length) {
      setAverageValue(Math.round(yDataOudStabilityScores.reduce((total: number, score: any) => (total + score), 0) / yDataOudStabilityScores.length));
      setCurrentValue(Math.round((yDataOudStabilityScores[yDataOudStabilityScores.length - 1] || 0)));
      if (yDataOudStabilityScores.length > 1) {
        setChangeValue(Math.round((yDataOudStabilityScores[yDataOudStabilityScores.length - 1] || 0)) - Math.round((yDataOudStabilityScores[yDataOudStabilityScores.length - 2] || 0)));
      } else {
        setChangeValue(0);
      }
    } else {
      setAverageValue(0);
      setCurrentValue(0);
      setChangeValue(0);
    }
  }, [yDataOudStabilityScores]);
  
  return (
    <AverageCurrentScore averageValue={averageValue} currentValue={currentValue} changeValue={changeValue} title="OUD Stability Score" helpText="The average value is based on full patient's history." />
  )
}
import { DashboardPatientInfoListItem } from "./DashboardPatientInfoListItem";
import CalendarEvent from '../../assets/CalendarEvent.png';
import CalenderWeek from '../../assets/CalendarWeek.png';
import CalendarMonth from '../../assets/CalendarMonth.png';
import PlusCircleLight from '../../assets/PlusCircleLight.png';
import PlusCircleDark from '../../assets/PlusCircleDark.png';
import FileRx from '../../assets/FileRx.png';
import RefreshDrop from '../../assets/RefreshDrop.png';
import { Box, Grid, List } from "@mui/material";
import { useSelector } from "react-redux";
import { dateRangeSelector, fetchedHeatmapCompoundsSelector, fetchedLabOrderedTestResultsSelector, fetchedStabilityScoresSelector, xAxisBupHistoryLabelsSelector, yDataBupHistoryResultsSelector, yDataSubstanceUseHeatmapStateSelector } from "./dashboardSlice";
import { useEffect, useState } from "react";
import moment from 'moment-timezone';

export const DashboardPatientInfoList: React.FC = () => {
  const stabilityScores: Array<any> = useSelector(fetchedStabilityScoresSelector);
  const labOrderedTestResults: Array<any> = useSelector(fetchedLabOrderedTestResultsSelector);
  const xAxisBupHistoryLabels = useSelector(xAxisBupHistoryLabelsSelector);
  const yDataBupHistoryResults = useSelector(yDataBupHistoryResultsSelector);
  const heatmapCompounds: any = useSelector(fetchedHeatmapCompoundsSelector);
  const yDataSubstanceUseHeatmapState = useSelector(yDataSubstanceUseHeatmapStateSelector);
  const dateRange = useSelector(dateRangeSelector);

  const [earliestApptDate, setEarliestApptDate] = useState<string>('None');
  const [latestApptDate, setLatestApptDate] = useState<string>('None');
  const [daysSinceLastSample, setDaysSinceLastSample] = useState<string>('None');
  const [totalSamples, setTotalSamples] = useState<string>('0');

  const [positiveOpioidTestsCount, setPositiveOpioidTestsCount] = useState<String>('0');
  const [positiveOpioidTestsPercent, setPositiveOpioidTestsPercent] = useState<String>('0');

  const [positiveNonOpioidTestsCount, setPositiveNonOpioidTestsCount] = useState<String>('0');
  const [positiveNonOpioidTestsPercent, setPositiveNonOpioidTestsPercent] = useState<String>('0');

  const [positiveBupAdherenceCount, setPositiveBupAdherenceCount] = useState<string>('0');
  const [positiveBupAdherenceCountPercent, setPositiveBupAdherenceCountPercent] = useState<string>('0');

  const [positiveAdulterationCount, setPositiveAdulterationCount] = useState<string>('0');
  const [positiveAdulterationCountPercent, setPositiveAdulterationCountPercent] = useState<string>('0');

  useEffect(() => {
    if (stabilityScores && stabilityScores.length) {
      const dateRangeFilter: Function = (dateRange === 'last_6_months' ? 
        (dateValue: string) => (moment().subtract(6, 'month').diff(moment(dateValue)) <= 0) : 
          (dateRange === 'last_1_year' ? 
            (dateValue: string) => (moment().subtract(1, 'year').diff(moment(dateValue)) <= 0) : 
            (dateValue: string) => true));
      const appts: Array<any> = stabilityScores.filter(score => dateRangeFilter(score.date)).map((score: any) => moment(score.date)).sort((d1, d2) => moment(d1).diff(moment(d2)));
      /* Check if appointments are empty after filtering for the date range */
      if(appts && appts.length > 0) 
      {
        setEarliestApptDate(appts[0].format('MMMM DD, YYYY'));
        setLatestApptDate(appts[appts.length -1].format('MMMM DD, YYYY'));
        setDaysSinceLastSample(String(moment().diff(appts[appts.length -1], 'day')));
        setTotalSamples(String(appts.length));
      } else {
        console.log("Appointments are filtered out for the date range: ", dateRange);
        setEarliestApptDate('None');
        setLatestApptDate('None');
        setDaysSinceLastSample('None');
        setTotalSamples('0');
      }
    } else {
      setEarliestApptDate('None');
      setLatestApptDate('None');
      setDaysSinceLastSample('None');
      setTotalSamples('0');
    }
  }, [heatmapCompounds, stabilityScores, dateRange]);
  
  useEffect(() => {
    if (labOrderedTestResults && labOrderedTestResults.length) {
      const appts: Array<string> = xAxisBupHistoryLabels;
      const fentanylIndex = heatmapCompounds['Fentanyl'].index;
      const otherOpioidsIndex = heatmapCompounds['Other Opioids'].index;
      let positiveTestCount = 0;

      const filteredHeatmap = [fentanylIndex, otherOpioidsIndex].map(i => yDataSubstanceUseHeatmapState[i]);
      positiveTestCount = appts.reduce((total: number, _: any, xAxisApptIndex: number) => {
        return (total + (filteredHeatmap.map(compoundHeatMapData => compoundHeatMapData[xAxisApptIndex]).filter(heatMapValue => heatMapValue === 1).length > 0 ? 1 : 0))
      }, 0);
      setPositiveOpioidTestsCount(String(positiveTestCount));
      setPositiveOpioidTestsPercent(`${!appts.length ? 0 : Math.round(100 * positiveTestCount / appts.length)}`);

      const nonOpioidHeatmap = Object.keys(heatmapCompounds).map(compoundName => (['Fentanyl', 'Other Opioids'].indexOf(compoundName) < 0 ? heatmapCompounds[compoundName].index : null)).filter(indexValue => indexValue !== null).map(i => yDataSubstanceUseHeatmapState[i]);
      positiveTestCount = appts.reduce((total: number, _: any, xAxisApptIndex: number) => {
        return (total + (nonOpioidHeatmap.map(compoundHeatMapData => compoundHeatMapData[xAxisApptIndex]).filter(heatMapValue => heatMapValue === 1).length > 0 ? 1 : 0))
      }, 0);
      setPositiveNonOpioidTestsCount(String(positiveTestCount));
      setPositiveNonOpioidTestsPercent(`${!appts.length ? 0 : Math.round(100 * positiveTestCount / appts.length)}`);
      const positiveBupResults: Array<number> = yDataBupHistoryResults.filter(result => result === 1);
      setPositiveBupAdherenceCount(String(positiveBupResults.length));
      if (totalSamples && Number(totalSamples) > 0) {
        setPositiveBupAdherenceCountPercent(`${Math.round(100*positiveBupResults.length / Number(totalSamples))}`);
      } else {
        setPositiveBupAdherenceCountPercent('0')
      }
      setPositiveAdulterationCount(String(positiveBupResults.filter(resultValue => resultValue >= 3000).length));
      setPositiveAdulterationCountPercent('0');
      if (totalSamples && Number(totalSamples) > 0) {
        setPositiveAdulterationCountPercent(`${Math.round(100*positiveBupResults.filter(resultValue => resultValue >= 3000).length / Number(totalSamples))}`);
      } else {
        setPositiveAdulterationCountPercent('0')
      }
    } else {
      setPositiveOpioidTestsCount('0');
      setPositiveOpioidTestsPercent('0');
      setPositiveNonOpioidTestsCount('0');
      setPositiveNonOpioidTestsPercent('0');
      setPositiveBupAdherenceCount('0');
      setPositiveBupAdherenceCountPercent('0');
      setPositiveAdulterationCount('0');
      setPositiveAdulterationCountPercent('0');
    }
  }, [totalSamples, labOrderedTestResults, xAxisBupHistoryLabels, yDataBupHistoryResults, yDataSubstanceUseHeatmapState, heatmapCompounds]);

  const datesAndSamplesSection = (
    <>
      <DashboardPatientInfoListItem
        title={earliestApptDate}
        caption="FIRST SAMPLE DATE"
        iconSrc={CalendarEvent}
      />
      <DashboardPatientInfoListItem
        title={latestApptDate}
        caption="MOST RECENT SAMPLE"
        iconSrc={CalenderWeek}
      />
      <DashboardPatientInfoListItem
        title={daysSinceLastSample}
        caption="DAYS SINCE LAST SAMPLE"
        iconSrc={CalendarMonth}
      />
      <DashboardPatientInfoListItem
        title={totalSamples}
        caption="TOTAL SAMPLES REPORTED"
        iconSrc={CalenderWeek}
      />
    </>
  )

  const testsStatsSection = (
    <>
      <DashboardPatientInfoListItem
        title={`${positiveOpioidTestsCount} ( ${positiveOpioidTestsPercent}% )`}
        caption="POSITIVE OPIOID TESTS"
        iconSrc={PlusCircleLight}
      />
      <DashboardPatientInfoListItem
        title={`${positiveNonOpioidTestsCount} ( ${positiveNonOpioidTestsPercent}% )`}
        caption="POSITIVE NON-OPIOID TESTS"
        iconSrc={PlusCircleDark}
      />
      <DashboardPatientInfoListItem
        title={`${positiveBupAdherenceCount} ( ${positiveBupAdherenceCountPercent}% )`}
        caption="BUPRENORPHINE ADHERENCE"
        iconSrc={FileRx}
        iconSrcWidth="28px"
      />
      <DashboardPatientInfoListItem
        title={`${positiveAdulterationCount} ( ${positiveAdulterationCountPercent}% )`}
        caption="POSS ADULTERATION"
        iconSrc={RefreshDrop}
      />
    </>
  )

  return (
    <Box>
      <Grid container sx={{ display: { xs: 'none', lg: 'flex' } }}>
        <Grid item lg={6}>
          <List sx={{ width: '100%' }}>
            {datesAndSamplesSection}
          </List>
        </Grid>
        <Grid item lg={6}>
          <List sx={{ width: '100%' }}>
            {testsStatsSection}
          </List>
        </Grid>
      </Grid>
      <List sx={{ width: '100%', display: { xs: 'block', lg: 'none' } }}>
        {datesAndSamplesSection}
        {testsStatsSection}
      </List>
    </Box>
  )
}